@import 'styles/function.scss';

.main {
    position: relative;

    border-radius: pxToRem(40);
    padding: pxToRem(8) pxToRem(10);
    cursor: pointer;
    border: pxToRem(1) solid #D2D2D2;
    outline: 0;
    background: #FFFFFF;
    height: pxToRem(32);
    margin: 0 pxToRem(8);

    font-weight: 500;
    font-size: pxToRem(12);
    line-height: pxToRem(14);
    text-align: center;
    letter-spacing: 0.01em;

    display: flex;
    justify-content: space-around;
    align-items: center;

    min-width: pxToRem(50);

    color: rgba(0, 0, 0, 0.6);
}

/* .main:hover {
    background-color: #F7F7F7;
}
.main:active {
    box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.3);
} */
/* .white {} */
.white:hover {
    background-color: #F7F7F7;
}
.white:active {
    box-shadow: 0px pxToRem(1) pxToRem(24) rgba(0, 0, 0, 0.3);
}

.red {
    background: #F44336;
    border-color: transparent;

    color: #fff;
}
.red:hover {
    background-color: #F22212;
}
.red:active {
    background: linear-gradient(90deg, #D28260 0%, #F22212 100%);
}

.blue {
    background: #0066FF;
    border-color: transparent;

    color: #fff;
}
.blue:hover{
    background-color: #005AE0;
}
.blue:active{
    background: linear-gradient(270deg, #513087 0%, #0066FF 100%);
}

.gray {
    background: #DADADA;
    border-color: transparent;

    color: #666;
}
.gray:hover{
    background-color: #cac9c9;
}
.gray:active{
    background: linear-gradient(270deg, #DADADA 0%, #cac9c9 100%);
}

.long {
    width: pxToRem(120);
}

.longest {
    width: pxToRem(150);
}

.dropIcon {
    flex-shrink: 0;
}

.copyIcon {
    margin-right: pxToRem(2);
}

.disabled {
    cursor: default;
    pointer-events: none;
    user-select: none;
    background: transparent;
    color: rgba(#ccc, .7);
    border: 1px solid #ccc;
}
