@import 'styles/function.scss';

.container {
  width: pxToRem(800);
  height: pxToRem(600);
  background: #F7F7F7;
  box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.25);
  border-radius: 8px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 9999;

  user-select: none;
}

.header {
  width: pxToRem(752);
  height: pxToRem(41);
  margin-top: pxToRem(10);
  padding: 0 pxToRem(24) pxToRem(7) pxToRem(24);
  display: flex;
  border-bottom: pxToRem(2) solid rgba(0, 0, 0, 0.12);

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerText {
  color: #4D4D4E;
  font-weight: 500;
  font-size: pxToRem(20);
  line-height: pxToRem(23);
}

.headerCloseIcon {
  width: pxToRem(24);
  height: pxToRem(24);
  cursor: pointer;

  & path {
    fill: #999999;
  }

  &:hover {
    transform: scale(1.3);
    transition: 0.2s ease;
  }
}

.inputsGroup {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: pxToRem(10) pxToRem(24);
}

.inputsGroupArea {
  height: pxToRem(70);
  max-height: pxToRem(80);
  width: pxToRem(315);
}

.inputsGroupAreaContainer {
  margin-top: pxToRem(24);
}

.inputAndSearchWrapSearch {
  width: pxToRem(340);
}

.filterBlock {
  display: flex;
  margin: pxToRem(5) pxToRem(24);
}

.filterBlockSearch {
  width: pxToRem(340);
}

.modalBody {
  height: pxToRem(400);
  padding: pxToRem(10) pxToRem(24);
}

.groupsList {
  display: flex;
  justify-content: space-between;
}

.groupsListActive {}

.groupsListDisabled {}

.groupsListHeader {
  color: #4D4D4E;
  font-size: pxToRem(18);
  text-align: center;
  margin-bottom: pxToRem(10);
}

.groupsListItemsWrap {
  background: #FFFFFF;
  border: pxToRem(1) solid #999999;
  border-radius: pxToRem(4);
  padding: 0;

  width: pxToRem(340);
  height: pxToRem(325);

  overflow-y: scroll;

  margin-bottom: pxToRem(10);

  &::-webkit-scrollbar-track {
    background: #FFFFFF;
    border-radius: pxToRem(4);
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.11);
  }
}

.groupsListItem {
  color: #4D4D4E;
  border-bottom: pxToRem(1) solid rgba(0, 0, 0, 0.05);
  padding: pxToRem(8) pxToRem(16);
  cursor: pointer;
  z-index: 99999;

  &:hover {
    background-color: #F7F7F7;
  }
}

.groupsListItemSelected {
  background-color: #EFEFEF;
}

.selectAllButton {
  align-self: center;
  margin: auto;
}

.moveButtons {
  display: flex;
  flex-direction: column;
  align-self: center;
}

.moveButtonsBtn {
  border-radius: 20px 20px;
  border: pxToRem(1) solid #D2D2D2;
  cursor: pointer;

  width: pxToRem(32);
  height: pxToRem(32);

  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: pxToRem(8);
}

.moveButtonsBtnToRight {
  transform: rotate(180deg);
}
.moveButtonsBtnToLeft {
  transform: rotate(0);
}

.modalFooter {
  height: pxToRem(48);
  border-top: pxToRem(2) solid rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: flex-end;
  padding: pxToRem(18) pxToRem(20);
}

.modalFooterButtonsWrap {
  display: flex;

  button {
    margin-top: 0;
    &:last-child {
      margin-right: 0;
    }
  }
}

.inputWrap {
  margin: pxToRem(24);
  margin-right: 0;
}
