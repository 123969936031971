@import 'styles/function.scss';

$tabShadow: 0px pxToRem(4) pxToRem(4) rgba(0, 0, 0, 0.25);
$activeColor: #2f80ed;

.container {
  display: flex;

  font-size: pxToRem(16);
  font-weight: 700;
  color: #616161;
}

.tab {
  display: flex;
  justify-content: center;
  flex: 1;

  padding: pxToRem(10) pxToRem(16);
  box-shadow: $tabShadow;

  cursor: pointer;

  &_active {
    color: $activeColor;
    box-shadow: $tabShadow, inset 0px pxToRem(-4) 0px 0px $activeColor;
  }
}

.tabLabel:not(:first-child) {
  margin-left: pxToRem(10);
}
