@import 'styles/function.scss';

.customContentStyles {
  overflow: auto;
  padding: 0;
}

.popupSpinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.popupList {
  width: 100%;

  overflow-y: auto;
}

.popupListItem {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: pxToRem(14);
  color: #4d4d4e;

  min-height: pxToRem(33);
  border-bottom: 1px solid #cccccc;

  padding: 0 pxToRem(8);

  &:hover {
    background-color: #e9e9e9;
  }

  &NoData {
    border-bottom: none;
  }
}

.popupListItemTrackerNumber {
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: pxToRem(8);
}

.popupListItemIcon {
  flex-shrink: 0;
  flex-grow: 0;

  width: pxToRem(18);
  height: pxToRem(18);

  margin-right: pxToRem(8);

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.popupListItemName {
  flex-grow: 1;
  margin-right: pxToRem(8);
}

.popupListItemGps {
  flex-grow: 0;
  margin-right: pxToRem(8);

  &Off g path {
    fill: #828282;
  }
  &On g path {
    fill: #2f80ed;
  }
  &Active path {
    fill: #2f80ed;
  }
}

.popupListItemNetwork {
  flex-grow: 0;

  &true circle {
    fill: #00bf78;
  }
  &false circle {
    fill: #999999;
  }
}

.customTooltip {
  background: #ffffff !important;
  border: 0.5px solid #0066ff !important;
  border-radius: 5px !important;
  width: fit-content !important;
  height: fit-content !important;
  padding: pxToRem(5) pxToRem(5) !important;
  color: #000000 !important;
  font-size: pxToRem(11) !important;
  line-height: pxToRem(11) !important;
  opacity: 1 !important;
  margin-top: pxToRem(20) !important;
  margin-left: 0 !important;

  z-index: 9999999 !important;
}
