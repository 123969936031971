@import 'styles/function.scss';

.modalControls {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    height: pxToRem(56);
    padding: 0 pxToRem(24);
}
