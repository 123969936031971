@import 'styles/function.scss';

.container {
  width: pxToRem(370);
  background: #f7f7f7;
  box-shadow: 0px 0px pxToRem(22) rgba(0, 0, 0, 0.25);
  border-radius: pxToRem(8);

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 4;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: pxToRem(20);
    border-bottom: pxToRem(1) solid rgba(#666, 0.12);

    &Title {
      font-weight: 500;
      font-size: pxToRem(20);
      color: #4d4d4e;
    }

    &CloseIcon {
      & svg {
        cursor: pointer;

        &:hover {
          transform: scale(1.5);
          transition: 0.2s ease;
        }
      }
    }
  }

  .body {
    padding: pxToRem(8) pxToRem(16);
    height: pxToRem(170);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: pxToRem(1) solid rgba(0, 0, 0, 0.12);
    padding: pxToRem(16);
  }
}

.configItem {
  &Wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    z-index: 99999;
  }

  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border: pxToRem(1) solid #666666;
  border-radius: pxToRem(4);
  padding: pxToRem(12) pxToRem(8);
  margin: pxToRem(16);

  &Name {
    cursor: pointer;
    user-select: none;
    font-size: pxToRem(14);
    color: #4d4d4e;
    margin-left: pxToRem(10);
  }
}
