@import 'styles/function.scss';

.container {
    display: flex;
    flex-direction: column;

    width: pxToRem(584);
    height: pxToRem(620);

    background-color: #F7F7F7;
}

.wrapper {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
}

.header {
    flex-shrink: 0;

    display: flex;
    justify-content: space-between;
    align-items: center;

    height: pxToRem(60);

    padding: 0 pxToRem(24);
}

.headerText {
  font-weight: 500;
  font-size: pxToRem(20);
  line-height: pxToRem(23);
  color: #4D4D4E;
}

.headerCloseIcon {
  cursor: pointer;

  &:hover {
      transform: scale(1.3)
  }
}

.body {
    flex-grow: 1;
    height: pxToRem(500);
    overflow-y: auto;

    border-bottom: pxToRem(1) solid rgba(0, 0, 0, 0.12);
    border-top: pxToRem(1) solid rgba(0, 0, 0, 0.12);
}

.inputGroup {
    display: flex;
    justify-content: space-between;
}

.inputWrap {
    margin: pxToRem(12) pxToRem(24);
    width: pxToRem(300);
}

.table {
  display: block;

  max-height: pxToRem(420);
  width: 100%;

  overflow: auto;

  padding: 0 pxToRem(24);
  margin: 0 auto;
}

.tableHeader {
  position: sticky;

  height: pxToRem(50);
  width: pxToRem(120);

  background: #E9E9E9;
  font-size: pxToRem(14);

  &:first-child {
      width: pxToRem(254);
      background: #C4C4C4;
      font-size: pxToRem(18);
  }
}

.tableHeaderCellWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.tableHeaderCellLabel {
  margin-bottom: pxToRem(4);
}

.tableBodyCell {
  background: rgba(233, 233, 233, 0.4);
  font-size: pxToRem(14);
  padding: pxToRem(3) pxToRem(8);

  &:first-child {
      background: #E9E9E9;
      font-weight: bold;
  }
}

.tableBodyCellCheckboxWrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footerButtons {
    flex-shrink: 0;

    display: flex;
    justify-content: flex-end;
    align-items: center;

    height: pxToRem(56);
    padding: 0 pxToRem(24);
}
