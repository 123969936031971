@import 'styles/function.scss';

.wrapper {
    border: pxToRem(1) solid rgba(210, 210, 210, 0.5);
    box-sizing: border-box;
    border-radius: pxToRem(4);
    position: relative;

    height: pxToRem(48);

    padding: pxToRem(8) pxToRem(16);

    &Focused {
        border: 1px solid #0066FF;
    }

    &:hover:not(.wrapperFocused) {
        border: 1px solid #4D4D4E;
    }

    &Disabled {
        user-select: none;
        pointer-events: none;
        color: rgba(0,0,0, .3);
    }
}

.label {
    user-select: none;
    pointer-events: none;

    font-size: pxToRem(10);
    line-height: pxToRem(13);
    letter-spacing: 0.01em;
    color: #666;

    margin-left: pxToRem(3);

    &Focused {
        color: #0066FF;
    }

    &Disabled {
        color: rgba(0, 0, 0, .3);
    }
}

.input {
    font-size: pxToRem(14);
    line-height: pxToRem(18);
    letter-spacing: 0.01em;
    width: calc(100% - 2rem);

    background-color: transparent;

    border: 0;
    outline: 0;
}

.input::placeholder {
    color: rgba(0, 0, 0, 0.2);
}

.wrapperWarning {
    border: pxToRem(1) solid #F44336;
}

.iconWarning {
    position: absolute;
    top: pxToRem(3);
    right: pxToRem(3);
    color: #F44336;
}

.iconBase {
    position: absolute;
    top: pxToRem(12);
    right: pxToRem(12);
    color: #666;
    width: pxToRem(22);
    height: pxToRem(22);
    cursor: pointer;

    &Focused path{
        fill: #0066FF;
    }
}

.iconClear {
    border: 1px solid rgba(210, 210, 210, 0.5);
    border-radius: pxToRem(4);

    right: pxToRem(36);
    padding: 0;

    svg {
        width: 90%;
        height: 90%;

        & path{
          fill: #666;
        }
      }

      &:hover {
        border-color: #4d4d4e;
      }

      &:focus {
        border-color: #0066ff;

        svg {

          & path{
            fill: #0066ff;
          }
        }
      }

      &:active {
        box-shadow: rgba(210, 210, 210, 1) 1px 1px 20px 0px inset;
      }

      &Disabled {
        user-select: none;
        pointer-events: none;

        svg {
          & path{
            fill: rgba(#000, .3);
          }
        }
    }
}

.isGood {
    color: #27AE60;
}

.isFileInput, .supportingLabel {
    display: none;
}

.supportingLabelIsFileInput {
    display: inline-block;

    color: rgba(0, 0, 0, 0.2);
    font-size: pxToRem(14);
    line-height: pxToRem(18);
    letter-spacing: 0.01em;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    max-width: pxToRem(260);
    height: pxToRem(48);
    padding: pxToRem(1) pxToRem(2);
}

.supportingLabelIsEstablishedValue {
    color: black;
}
