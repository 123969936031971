@import 'styles/function.scss';

.container {
  width: 100%;
}

.wrapper {
  border: pxToRem(1) solid rgba(210, 210, 210, 0.5);
  box-sizing: border-box;
  border-radius: pxToRem(4);
  position: relative;

  height: pxToRem(48);

  padding: pxToRem(8) pxToRem(16);

  z-index: 1;

  &:hover:not(.wrapperFocused):not(.wrapperWarning) {
    border: 1px solid #4d4d4e;
  }
}

.wrapperWarning {
  border: pxToRem(1) solid #f44336;
}

.wrapperFocused {
  border: 1px solid #0066ff;
}

.wrapperDisabled {
  user-select: none;
  pointer-events: none;
  color: rgba(#000, .3);
}

.iconBase {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
}

.iconBaseFocused {
  & path{
    fill: #0066ff;
  }
}

.iconBaseDisabled {
  user-select: none;
  pointer-events: none;
  & path{
    fill: rgba(#000, .3);
  }
}

.label {
  user-select: none;
  pointer-events: none;
  font-size: pxToRem(10);
  line-height: pxToRem(13);
  letter-spacing: 0.01em;
  margin-left: pxToRem(3);
  color: #666;
}

.labelFocused {
  color: #0066ff;
}

.labelDisabled {
  user-select: none;
  pointer-events: none;
  color: rgba(#000, .3);
}

.input {
  font-size: pxToRem(14);
  line-height: pxToRem(18);
  letter-spacing: 0.01em;
  width: 18em;
  max-width: 100%;

  background-color: transparent;

  border: 0;
  outline: 0;

  &::placeholder {
    color: rgba(0, 0, 0, 0.2);
  }
}

.isGood {
  color: #27ae60;
}

.selectedOption {
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: rgba(0, 0, 0, 0.04);
  border-radius: 50px;

  font-size: pxToRem(12);
  color: #4d4d4e;

  max-width: calc(100% - 3rem);

  padding: pxToRem(4) pxToRem(10);

  & > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.selectedOptionCloseIcon {
  flex-shrink: 0;
  width: pxToRem(12);
  height: pxToRem(12);
  cursor: pointer;
}

.selectedOptionCloseIconHide {
  display: none;
}

.optionsList {
  background: #ffffff;

  overflow-y: auto;

  max-height: pxToRem(180);

  border-radius: pxToRem(4);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);

  padding: 0;
  margin-top: pxToRem(0);

  &::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: pxToRem(4);
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.11);
  }
}

.optionsListItem {
  cursor: pointer;

  text-decoration: none;
  font-size: pxToRem(14);
  color: #4D4D4E;

  border-bottom: pxToRem(1) solid rgba(0, 0, 0, 0.05);

  padding: pxToRem(12) pxToRem(16);

  &:hover {
    background: #f7f7f7;
  }
}

.iconWarning {
  position: absolute;
  top: pxToRem(3);
  right: pxToRem(3);
  color: #f44336;
}
