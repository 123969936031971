@import 'styles/function.scss';


.popup {

  &Container {
    position: absolute;
    top: 50%;
    left: 85%;
    transform: translate(-85%, -50%);

    z-index: 9999;

    width: pxToRem(360);
    background: #fff;
  }

  &Header {
    border-bottom: 1px solid #CCCCCC;
  }

  &Controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    height: pxToRem(56);
    padding: 0 pxToRem(24);

    border-top: 1px solid #CCCCCC;
  }

  &Field {
    margin: 16px;

    &:first-child {
      margin: 16px 21px 16px 16px;
    }

    &:last-child {
      margin: 16px 16px 16px 21px;
    }
  }
}
