@import 'styles/function.scss';

ul, li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.title {
  cursor: pointer;
  user-select: none;
  border-bottom: 1px solid #DCDCDC;
  padding: pxToRem(8) pxToRem(8);
  margin: 0;
}

.templatesList {
  height: calc(100% - 35px);
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: pxToRem(4);
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.51);
  }
}

.template {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;

  border-bottom: 1px solid #DCDCDC;
  padding: pxToRem(10);
}

.templateLeftSide, .templateRightSide {
  display: flex;
  align-items: center;
}

.templateName {
  cursor: pointer;
  user-select: none;

  max-width: pxToRem(290);

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  margin-left: pxToRem(10);
}

.templateIcon {
  cursor: pointer;
  margin-left: pxToRem(8);

  &:first-child {
    margin-left: 0;
  }

  &Disabled {
    pointer-events: none;
    opacity: .5;
  }
}
