@import 'styles/function.scss';

.content {
  display: flex;

  font-size: pxToRem(14);
}

.column {
  flex: 1;

  margin-right: pxToRem(50);

  &:last-child {
    margin-right: 0;
  }
}

.row {
  margin-bottom: pxToRem(16);

  &:last-child {
    margin-bottom: 0;
  }
}

.rowWithTooltip {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.rowWithOptions {
  margin-bottom: pxToRem(8);
}

.rowInner {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);

  margin-left: pxToRem(30);
  margin-bottom: 0;
}

.group {
  position: relative;

  border: 1px solid #D2D2D2;
  border-radius: pxToRem(4);
  padding: pxToRem(8) pxToRem(16);
}

.groupLabel {
  font-size: pxToRem(10);
  margin-bottom: pxToRem(8);
}

.groupListItem {
  display: flex;
  align-items: center;

  user-select: none;
  cursor: pointer;

  margin-bottom: pxToRem(8);

  &:last-child {
    margin-bottom: 0;
  }
}

.groupTooltipIcon {
  position: absolute;
  right: pxToRem(16);
  top: pxToRem(8);
}

.sortableListItem {
  display: flex;
  align-items: center;

  font-size: pxToRem(14);

  z-index: 9999;
  margin-bottom: pxToRem(8);
}

.sortableListItemManualSorting {
  cursor: n-resize;
  flex-shrink: 0;
}

.checkboxWrapper {
  display: flex;
  align-items: center;

  user-select: none;
  cursor: pointer;
}

.checkbox {
  margin: 0 pxToRem(8);
}

.checkboxNoMarginLeft {
  margin-left: 0;
}

.radioButtonWrapper {
  display: flex;
  align-items: center;

  user-select: none;
  cursor: pointer;

  margin-bottom: pxToRem(8);
}

.radioButton {
  margin-right: pxToRem(8);
}
