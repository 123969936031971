@import 'styles/function.scss';

.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: pxToRem(55) minmax(pxToRem(260), 1fr) 1fr pxToRem(50);

  height: 100%;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: pxToRem(16) 0 pxToRem(8) 0;
  padding: 0 pxToRem(8);
}

.controlsSearch {
  width: 100%;
}

.controlsCreate {
  button {
    margin-right: 0;
    width: pxToRem(120);
  }
}
