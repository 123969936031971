@import 'styles/function.scss';

.tableHeader {
  background: #c4c4c4;
  font-size: pxToRem(18);
  height: pxToRem(32);
  color: #4d4d4e;
}

.tableHeaderDate {
  width: pxToRem(82);
}
.tableHeaderTime {
  width: pxToRem(62);
}
.tableHeaderUser {
  width: pxToRem(196);
}
.tableHeaderLogin {
  width: pxToRem(114);
}
.tableHeaderText {
  width: pxToRem(320);
}

.tableBodyCell {
  background: rgba(233, 233, 233, 0.4);
  font-size: pxToRem(14);
  padding: pxToRem(4) pxToRem(6);
}