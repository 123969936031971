@import 'styles/function.scss';

.container {
  margin-bottom: 0.5rem;
  border: pxToRem(1) solid rgba(210, 210, 210, 0.5);
  border-radius: pxToRem(4);

  &:hover:not(.wrapperFocused) {
    border: 1px solid #4d4d4e;
  }
  &Warning {
    border-color: #F44336;
  }
  &Focused {
    border-color: #0066FF !important;
  }
  &Disabled {
    user-select: none;
    pointer-events: none;
  }
}

.select {
  border: 1px solid transparent;

  &:hover {
    border: 1px solid transparent !important;
  }
}

.selectOptionsContainer {
  left: pxToRem(18);
}

.valuesList {
  list-style: none;
  overflow-y: auto;

  max-height: pxToRem(140);

  margin: 0;
  padding: 0;
}

.valuesItem {
  display: flex;
  align-items: center;

  font-size: pxToRem(14);

  background-color: rgba(black, .04);
  border-radius: pxToRem(50);

  width: fit-content;

  padding: pxToRem(8);
  margin: pxToRem(8);
}

.valuesItemInline {
  display: inline-flex;
}

.valuesItemDisabled {
  pointer-events: none;
  opacity: .7;
}

.value {
  margin: 0 pxToRem(16) 0 pxToRem(4);
}

.closeBtn {
  svg {
    vertical-align: middle;
  }
}

.closeModalIcon {
  cursor: pointer;
  width: 16px;
  height: 16px;

  &:hover {
    transform: scale(1.5);
  }
}

.closeModalIconDisabled {
  &:hover {
    transform: none;
  }
}
