@import 'styles/function.scss';

.wrapper {
  position: fixed;

  font-size: pxToRem(14);
  color: #4d4d4e;

  max-width: pxToRem(450);
  background-color: #ffffff;

  border-collapse: collapse;
  border: 1px solid #cccccc;

  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  z-index: 99999;
}

.headerInfo {
  display: flex;
  align-items: center;

  h4 {
    margin: 0;
  }
}

.headerTitle {
  user-select: none;
  max-width: pxToRem(300);
}

.headerIcon {
  display: inline-block;
  flex-shrink: 0;

  width: pxToRem(24);
  height: pxToRem(24);

  margin-right: pxToRem(8);

  path {
    fill: black;
  }
}

.headerBtnControl {
  cursor: pointer;

  width: pxToRem(24);
  height: pxToRem(24);

  transition: transform 0.25s;

  &:hover {
    transform: scale(1.2);
  }
}

.contentName {
  font-weight: bold;
  color: #4d4d4e;
  margin-bottom: pxToRem(8);
}

.contentLink {
  margin-right: pxToRem(8);
}

.contentDates {
  margin-top: pxToRem(8);
}

.contentName,
.contentLocation,
.contentDescription,
.contentDate {
  padding-left: pxToRem(8);
  border-left: 1px solid #4d4d4e;
}

.contentLocation,
.contentDate {
  margin-bottom: pxToRem(8);
}

.contentPopup {
  border-bottom: 1px solid #cccccc;
}

.contentPopupVideoCamera {
  padding-bottom: 0;
  border: none;
}

.footerPopupVideoCamera {
  padding-bottom: 0;
}

.footerVideoCamera {
  display: flex;
  height: 100%;
}

.footerContainer {
  max-height: pxToRem(350);
  overflow-y: auto;
}

.infoLabel {
  font-size: pxToRem(10);
  margin-bottom: pxToRem(3);
  opacity: 0.8;
}

.infoText {
  font-size: pxToRem(13);
  padding-left: pxToRem(8);
  border-left: 1px solid #4d4d4e;
}

.valuesWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: pxToRem(5) 0;
}

.svgWrapper {
  width: pxToRem(104);
  height: pxToRem(96);
}
.svgTitle,
.svgValue {
  font-size: pxToRem(14);
  font-weight: bold;
}
.svgLimit {
  font-size: pxToRem(9);
  fill: #ff8f00;
}
.svgMeasureUnit {
  font-size: pxToRem(10);
  fill: #666;
}
.svgRange {
  font-size: pxToRem(10);
  font-weight: bold;
}
.svgMainArcPath,
.svgLimitArcPath {
  transition: stroke-dashoffset 0.5s ease-in-out;
}
