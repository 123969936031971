@import 'styles/function.scss';

.layout {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: rgba(77, 77, 78, 0.6);
    z-index: 9999;
}

.alert {
    z-index: 99999;
}

.default {
    border-radius: pxToRem(5);

    background-color: #f1f1f1;
}

.big {
    width: pxToRem(800);
    height: pxToRem(625);
}

.small {
    width: pxToRem(400);
    height: pxToRem(304);
}

.medium {
    width: pxToRem(400);
    height: pxToRem(304);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: pxToRem(63);

    padding: 0 pxToRem(24);
    font-weight: 500;
    font-size: pxToRem(20);
    line-height: pxToRem(23);
}

.controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    height: pxToRem(56);
    padding: 0 pxToRem(24);
}

.body {
    height: pxToRem(440);
    border-bottom: pxToRem(1) solid rgba(0, 0, 0, 0.12);
    border-top: pxToRem(1) solid rgba(0, 0, 0, 0.12);
}
