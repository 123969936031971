@import 'styles/function.scss';

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.list {
  font-size: pxToRem(14);
  height: calc(100vh - 240px);
  overflow-y: auto;
}

.listHeader {
  user-select: none;
  display: flex;
  justify-content: space-between;

  color: #4d4d4e;
  font-weight: bold;
  white-space: nowrap;

  border-bottom: 1px solid #ccc;
  padding: pxToRem(12) pxToRem(16);
}

.datesHeader {
  margin-right: pxToRem(25);
}

.listItem {
  cursor: pointer;
  display: flex;
  justify-content: space-between;

  color: #4d4d4e;

  border-bottom: 1px solid #ccc;
  padding: pxToRem(12) pxToRem(16);

  &:hover {
    background-color: #e9e9e9;
  }
}

.listItemChosen {
  background-color: #e9e9e9;
}

.listItemName {
  min-width: pxToRem(100);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dates {
  display: flex;
  align-items: center;
}

.date {
  white-space: nowrap;
  margin-right: pxToRem(18);
}

.copyBtn {
  $size: 16;
  position: relative;
  width: pxToRem($size);
  height: pxToRem($size);

  &:hover {
    transform: scale(1.2);
  }

  &Disable {
    pointer-events: none;
    opacity: 0.5;
  }
}

.copyBtnIcon {
  position: absolute;
  top: -50%;
  transform: translateY(15%);
}

.buttons {
  display: flex;
  justify-content: flex-end;
  padding: pxToRem(16) 0;
  margin-top: auto;
}
