@import 'styles/function.scss';

.unitItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: pxToRem(40);
  border-bottom: pxToRem(1) solid #cccccc;

  &InGroup {
    margin-left: pxToRem(30);
  }

  &Info {
    padding-left: pxToRem(8);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: calc(100% - #{pxToRem(170)});
  }

  &Checkbox {
    margin-right: pxToRem(10);
  }

  &Image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: pxToRem(17);
  }

  &Name {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: pxToRem(5);

    &Row {
      flex-direction: row;
    }
  }

  &NameValue {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &Above, &Under {
      white-space: nowrap;
    }

    &Above {
      margin-right: pxToRem(5);
    }

    &FixWidth {
      width: 100%;
    }
  }

  &TrackableNumber {
    margin-right: pxToRem(4);
  }
}

.unitButtons {
  display: flex;
}

.unitButtons svg,
.unitButtons img,
.name svg {
  cursor: pointer;
}

.unitButtonsIcon {
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  width: pxToRem(28);
  height: pxToRem(20);

  margin: 0 pxToRem(2);

  &0 path {
    fill: #c8c8c8;
  }
  &1 path {
    fill: #f44336;
  }
  &2 path {
    fill: #ff8f00;
  }
  &3 path {
    fill: #efe100;
  }
  &4 path {
    fill: #00bf78;
  }

  &Text {
    position: absolute;
    left: pxToRem(3);
    color: #ffffff;
    font-size: pxToRem(8);
  }

  &Disabled {
    pointer-events: none;
    opacity: .5;
  }
}

.iconConnectionState {
  &true circle {
    fill: #00bf78;
  }
  &false circle {
    fill: #999999;
  }
}

.iconGpsState {
  &Off g path {
    fill: #828282;
  }
  &On g path {
    fill: #2f80ed;
  }
  &Active path {
    fill: #2f80ed;
  }
}


.customTooltip {
  background: #ffffff !important;
  border: 0.5px solid #0066ff !important;
  border-radius: 5px !important;
  width: fit-content !important;
  height: fit-content !important;
  padding: pxToRem(5) pxToRem(5) !important;
  color: #000000 !important;
  font-size: pxToRem(11) !important;
  line-height: pxToRem(11) !important;
  opacity: 1 !important;
  margin-top: pxToRem(20) !important;
  margin-left: 0 !important;

  z-index: 9999999 !important;
}

.unitItemTitle {
  width: calc(100% - #{pxToRem(74)});
  cursor: pointer;
}
