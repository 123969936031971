@import 'styles/function.scss';

.inputWrap {
    margin: pxToRem(24);
}

.lineWrap {
    display: flex;
    justify-content: space-between;
    margin: pxToRem(24);
}

.smallInput {
    width: pxToRem(160);
}