@import 'styles/function.scss';

.container {
    position: relative;
}

.label {
    position: absolute;
    top: pxToRem(10);
    left: pxToRem(20);
    color: #666666;
    font-size: pxToRem(10);

    &Focused {
        color: #0066FF;
    }
}

.textarea {
    resize: vertical;
    border: pxToRem(1) solid rgba(210, 210, 210, 0.5);
    border-radius: pxToRem(4);
    width: pxToRem(322);
    padding: pxToRem(25) pxToRem(10) pxToRem(1) pxToRem(18);
    background: #FFF;
    min-height: pxToRem(25);
    background-color: transparent;

    outline: none;

    &::placeholder {
        font-size: pxToRem(14);
        color: rgba(0, 0, 0, 0.2);
    }

    &:hover {
        border: 1px solid #4D4D4E;
    }

    &:focus {
        border: 1px solid #0066FF;
    }

    &.warning {
        border: pxToRem(1) solid #F44336;
    }
}

.iconWarning {
    position: absolute;
    top: pxToRem(3);
    right: pxToRem(3);
    color: #F44336;
}
