@import 'styles/function.scss';

.container {
    position: relative;
    height: calc(100vh - 90px);
    overflow-y: scroll;
}

.sizesGroup {
    display: flex;

    .shortInput {
        width: pxToRem(170);
        margin-right: -(pxToRem(8));
    }
}

.buttonsGroup {
    display: flex;
    justify-content: flex-end;
    margin-top: pxToRem(40);
    margin-right: pxToRem(20);
}

.textAreaWrapper {
    margin-left: pxToRem(8);
}

.areaStyle {
    width: pxToRem(355);
    min-height: pxToRem(50);
}

.inputWrap {
    margin: pxToRem(24);
}

.row {
    display: flex;

    margin-left: pxToRem(24);
}
