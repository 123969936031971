@import 'styles/function.scss';

.container {
    display: flex;
    align-items: center;

    &Disabled {
      pointer-events: none;

      .switch input:checked + .slider {
        opacity: .3;
        background-color: black;
      }
    }

    &Indeterminate {
      opacity: 0.3;
    }
}

.switch {
  position: relative;
  display: inline-block;
  width: pxToRem(32);
  height: pxToRem(18);

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: pxToRem(34);
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: '';
      height: pxToRem(14);
      width: pxToRem(14);
      left: pxToRem(2);
      bottom: pxToRem(2);
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
    }
  }

  & input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: #0066FF;
    }
    &:focus + .slider {
      box-shadow: 0 0 pxToRem(1) #2196f3;
    }

    &:checked + .slider:before {
      transform: translateX(pxToRem(14));
    }
  }
}

.title {
    margin-left: pxToRem(8);
    color: #4D4D4E;
    font-size: pxToRem(14);
}
