@import 'styles/function.scss';

.buttonBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: pxToRem(16) 0 pxToRem(8) 0;
  padding: 0 pxToRem(8);
}

.searchWrapper {
  min-width: pxToRem(265);
  width: 100%;
}

.geozoneCreateButton {
  margin-left: pxToRem(8);

  button {
    width: pxToRem(120);
  }
}

.groupWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-weight: 500;
  font-size: pxToRem(14);
  line-height: pxToRem(16);
  color: #4d4d4e;
  height: pxToRem(40);
  border-bottom: pxToRem(1) solid #ccc;
}

.geozoneWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-weight: 500;
  font-size: pxToRem(14);
  line-height: pxToRem(16);
  color: #4d4d4e;
  height: pxToRem(40);
  border-bottom: pxToRem(1) solid #ccc;
}

.geoZonesListHead {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-weight: 500;
  font-size: pxToRem(14);
  line-height: pxToRem(16);
  height: pxToRem(40);
  border-bottom: pxToRem(1) solid #ccc;

  border-color: #000;
  color: #000;
}

.geoZonesListInfo {
  padding-left: pxToRem(10);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.geoZonesListInfoTitle {
  margin-left: pxToRem(10);

  cursor: pointer;
}

.geoZonesListInfoExpandButton {
  flex-shrink: 0;
  margin-right: pxToRem(20);
}

.geoZonesIcons {
  display: flex;
}

.geoZonesIconsIcon {
  width: pxToRem(28);
  height: pxToRem(20);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 pxToRem(2);
  position: relative;
}

.geoZonesIconsIconPointer {
  cursor: pointer;
}

.geoZonesIconsIconPointerDisabled {
  pointer-events: none;
  opacity: 0.5;
}

.geoZonesIconsCount {
  font-weight: 500;
  font-size: pxToRem(14);
  color: #000000;
}

.customTooltip {
  background: #ffffff !important;
  border: 0.5px solid #0066ff !important;
  border-radius: 5px !important;
  width: fit-content !important;
  height: fit-content !important;
  padding: pxToRem(5) pxToRem(5) !important;
  color: #000000 !important;
  font-size: pxToRem(11) !important;
  line-height: pxToRem(11) !important;
  opacity: 1 !important;
  margin-top: pxToRem(20) !important;
  margin-left: 0 !important;

  z-index: 9999999 !important;
}
