@import 'styles/function.scss';

.wrapper {
    background-color: #fff;
    border: pxToRem(1) solid #D2D2D2;
    box-sizing: border-box;
    border-radius: pxToRem(44);
    height: pxToRem(32);
    padding: 0 pxToRem(10);

    display: flex;
    align-items: center;
}

.input {
    border: 0;
    outline: 0;
    width: calc(100% - 32px);
    height: pxToRem(30);
    padding: 0 pxToRem(5);

    border-radius: pxToRem(5);

    font-size: pxToRem(12);
    line-height: pxToRem(14); 
}

.input:placeholder {
    color: #999;
}

.icon {
    width: pxToRem(32);
    height: pxToRem(32);
}
