@import 'styles/function.scss';

$border-color: rgba(0, 0, 0, 0.5);

.wrapper {
  position: absolute;

  width: pxToRem(10);
  height: pxToRem(10);

  bottom: pxToRem(1);
  right: pxToRem(1);

  cursor: se-resize;
}

.arrows,
.arrows::before {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border-right: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
}

.arrows {
  position: relative;

  width: 80%;
  height: 80%;

  &::before {
    content: '';
    display: block;
    position: absolute;

    width: 40%;
    height: 40%;
  }
}
