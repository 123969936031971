@import 'styles/function.scss';

.pointer {
  cursor: pointer;
  margin: 0 pxToRem(10);
}

.container {
  background: rgba(247, 247, 247, 0.85);
  position: absolute;
  right: pxToRem(6);
  z-index: 4;
  top: pxToRem(62);
  width: pxToRem(312);
  padding: pxToRem(16);
  border-radius: pxToRem(4);
  user-select: none;

  color: #4d4d4e;
}

.main {
  border-bottom: pxToRem(1) solid #4d4d4e;

  .title {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: pxToRem(18);

    margin-bottom: pxToRem(11);
  }

  .timeline {
    margin-bottom: pxToRem(5);

    .timelineDates {
      display: flex;
      justify-content: space-between;

      margin-bottom: pxToRem(8);
    }

    .timelineScale {
      display: block;
      height: 100%;
      background-color: #0066ff;
      box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3), inset 0 -2px 6px rgba(0, 0, 0, 0.4);
      overflow: hidden;
      width: 0;
      user-select: none;
      z-index: 997;
    }

    .timelineScaleWrap {
      height: pxToRem(16);
      border: pxToRem(1) solid #999999;
      cursor: pointer;
      z-index: 999;
      background-color: transparent;
    }
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.buttonsSkip {
  cursor: pointer;
}

.buttonsSkipPlayback {
  transform: rotate(180deg);
}

.additional {
  padding-top: pxToRem(10);

  .speed {
    width: 100%;
    margin-bottom: pxToRem(16);
  }

  .speedTitle {
    text-align: center;
    font-size: pxToRem(14);
    margin-bottom: pxToRem(6);
  }

  .speedSlider {
    -webkit-appearance: none;
    width: 100%;
    height: pxToRem(8);
    border-radius: pxToRem(4);
    border: 1px solid #666666;
    box-sizing: border-box;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;

    &:hover {
      opacity: 1;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: pxToRem(16);
      height: pxToRem(16);
      border-radius: 50%;
      background: #0066ff;
      cursor: pointer;
    }

    &::-moz-range-thumb {
      width: pxToRem(16);
      height: pxToRem(16);
      border-radius: 50%;
      background: #0066ff;
      cursor: pointer;
    }
  }

  .trackers {
    &Item {
      display: flex;
      border-top: pxToRem(1) solid #cccccc;
      padding: pxToRem(8) 0;

      &Color {
        height: pxToRem(48);
        width: pxToRem(8);
      }

      &Info {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        font-size: pxToRem(10);
        margin-left: pxToRem(8);

        &Name {
          font-weight: 500;
          font-size: pxToRem(12);
        }
      }
    }
  }
}

.minimizedPlayer {
  position: absolute;
  z-index: 4;
  top: auto;
  left: auto;
  right: pxToRem(10);
  bottom: pxToRem(60);
  width: pxToRem(312);
  padding: pxToRem(16);
  border-radius: pxToRem(4);

  background: rgba(247, 247, 247, 0.85);
}

.headBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-weight: 500;
  font-size: pxToRem(15);
  line-height: pxToRem(19);

  margin-bottom: pxToRem(5);
}

.headBlockText {
  display: flex;
  align-items: center;
}

.headBlockText span {
  padding-left: pxToRem(5);
}

.headBlockClose {
  transform: scale(1.3);
  cursor: pointer;

  &:hover {
    transform: scale(1.5);
    transition: 0.2s ease;
  }
}

.headBlockMinimize {
  transform: scale(1.3);
  cursor: pointer;
  margin-right: 15px;

  &:hover {
    transform: scale(1.5);
    transition: 0.2s ease;
  }
}

.headBlockHide {
  cursor: pointer;
}

.headBlockHideUp {
  transform: rotate(-180deg);
  animation-duration: 0.5s;
  transition: 0.2s ease;
}

.headBlockHideDown {
  transform: rotate(0deg);
  animation-duration: 0.5s;
  transition: 0.2s ease;
}

.info {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  position: relative;
  line-height: 26px;
}
