@import 'styles/function.scss';

.wrapper {
  display: flex;
  align-items: center;

  color: #fff;
}

.list {
  display: flex;
  list-style: none;

  margin: 0;
  padding: 0;
}

.item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: pxToRem(5.5);
}

.value {
  font-size: pxToRem(11);
  margin: 0 pxToRem(7);
  width: pxToRem(25);
  text-align: center;
}


.customTooltip {
  background: #ffffff !important;
  border: 0.5px solid #0066ff !important;
  border-radius: 5px !important;
  width: fit-content !important;
  height: fit-content !important;
  padding: pxToRem(5) pxToRem(5) !important;
  color: #000000 !important;
  font-size: pxToRem(11) !important;
  line-height: pxToRem(11) !important;
  opacity: 1 !important;
  margin-top: 0 !important;
  margin-left: 0 !important;

  z-index: 9999999 !important;
}
