@import 'styles/function.scss';

.buttonIcon {
  position: relative;
  cursor: pointer;
}

.buttonIconChildren {
  display: inline-block;
  margin-right: 1px;
}

.buttonIconArrow{
  display: inline-block;
  position: absolute;
  bottom: -2px;
}

%triangle {
  display: inline-block;
  position: relative;
  bottom: -2px;

  width: 0;
  height: 0;

  border: 3px solid transparent;
  border-top-color: black;
  border-bottom-width: 0;
  border-left-width: 2px;
  border-right-width: 2px;
}

.ascending{
  &:before {
    @extend %triangle;
    content: '';
  }
}

.descending{
  &:before {
    @extend %triangle;
    content: '';
    transform: rotate(180deg);
  }
}
