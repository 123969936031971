@import 'styles/function.scss';

ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.list {
  width: pxToRem(310);
  font-size: pxToRem(14);
}

.listItem {
  cursor: pointer;
  color: #4D4D4E;

  border-bottom: 1px solid #ccc;
  padding: pxToRem(12) pxToRem(16);
  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background-color: #E9E9E9;
  }
}

.listItemChosen {
  background-color: #E9E9E9; 
}