@import 'styles/function.scss';

.wrapper {
  position: relative;
  height: calc(100vh - 90px);
  overflow-y: scroll;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  border: 1px solid #ccc;
  padding: pxToRem(20) pxToRem(16);
}

.headerTitle {
  user-select: none;
  font-size: pxToRem(20);
  font-weight: 400;
  margin: 0;
}

.content {
  border: 1px solid #ccc;
  padding: pxToRem(20) pxToRem(16);
}

.headerClosebtn {
  cursor: pointer;
  width: pxToRem(24);
  height: pxToRem(24);
  transform: scale(1.3);

  &:hover {
    transform: scale(1.7);
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding: pxToRem(20) pxToRem(16);

  button {
    &:last-child {
      margin-right: 0;
    }
  }
}

.row {
  input {
    width: calc(100% - 24px);
  }
}

.row:not(:last-child) {
  margin-bottom: pxToRem(8);
}

.rowTextarea {
  textarea {
    width: calc(100% - 30px);
    height: 0;
  }
}

.rowDatepicker {
  display: block !important;
  margin-bottom: pxToRem(8);
}

.rowNobottommargin {
  margin-bottom: 0 !important;
}

.rowSelect {
  width: pxToRem(300);
}

.rowCheckboxWrapper {
  display: flex;
  align-items: flex-start;

  input {
    width: pxToRem(18);
    height: pxToRem(18);
  }
}

.rowText {
  cursor: pointer;
  user-select: none;
  max-width: pxToRem(350);
  margin-left: pxToRem(10);
}

.rowSome {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.rowSomeInput {
  flex-shrink: 0;
  margin-right: pxToRem(8);
  width: 49%;

  input {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &:last-child {
    margin-right: 0;
  }
}

.rowSomeInputFixWidth {
  input {
    width: 49%;
  }
}
