@import 'styles/function.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  max-height: pxToRem(450);
  overflow-y: auto;

  border-top: 1px solid #000;

  padding: pxToRem(8) pxToRem(16) pxToRem(16);
}

.row {
  margin-bottom: pxToRem(8);

  &:last-child {
    margin-bottom: 0;
  }
}

.rowTitle {
  user-select: none;
  margin: 0;
  margin-bottom: pxToRem(8);
}

.rowButtonsList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;

  padding: 0 pxToRem(16);
}

.rowButton {
  width: 25%;
  max-width: pxToRem(150);
  margin: 0 pxToRem(8) 0 0;

  &:last-child {
    margin: 0;
  }
}

.rowButtonFooter {
  min-width: pxToRem(176);
}

.rowDatepickerList {
  display: flex;
  flex-wrap: nowrap;
}

.rowDatepicker {
  flex: 1;
  margin-left: pxToRem(8);
  width: pxToRem(184);

  &:first-child {
    margin-left: 0;
  }
}

.datePickerInput {
  input {
    width: auto;
  }
}

.popperDatePicker {
  z-index: 10 !important;
}

.multipleSelectGroupValues {
  max-height: pxToRem(300);
}

.popupHeader {
  font-size: pxToRem(20);
}

.popupContent {
  line-height: 128.9%;
  font-size: pxToRem(14);
  padding: pxToRem(12) 0;
}

.popupFooter {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 100%;
}
