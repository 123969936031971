@import 'styles/function.scss';

.modalContent {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}

.modalCol {
    width: pxToRem(290);
}
.modalColSlim {
    width: pxToRem(200);
}

.inputWrap {
    margin: pxToRem(24);

    &Bottom {
        margin: pxToRem(24);
        margin-bottom: 0;
    }
}
.photoWrap {
    margin: pxToRem(24) pxToRem(24) pxToRem(24) pxToRem(29);
}
.switchContainer {
    margin-left: pxToRem(24);
    margin-top: pxToRem(4);
}

.banBlock {

    &Switch {
        margin-top: pxToRem(24);
        margin-left: pxToRem(24);
    }
}
