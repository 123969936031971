* {
    font-family: Roboto, sans-serif;
}

@font-face {
    font-family: 'Roboto';
    src: url('assets/fonts/Roboto-Thin.woff2') format('woff2'),
        url('assets/fonts/Roboto-Thin.woff') format('woff'),
        url('assets/fonts/Roboto-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('assets/fonts/Roboto-ThinItalic.woff2') format('woff2'),
        url('assets/fonts/Roboto-ThinItalic.woff') format('woff'),
        url('assets/fonts/Roboto-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('assets/fonts/Roboto-Light.woff2') format('woff2'),
        url('assets/fonts/Roboto-Light.woff') format('woff'),
        url('assets/fonts/Roboto-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('assets/fonts/Roboto-LightItalic.woff2') format('woff2'),
        url('assets/fonts/Roboto-LightItalic.woff') format('woff'),
        url('assets/fonts/Roboto-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('assets/fonts/Roboto-Regular.woff2') format('woff2'),
        url('assets/fonts/Roboto-Regular.woff') format('woff'),
        url('assets/fonts/Roboto-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('assets/fonts/Roboto-Italic.woff2') format('woff2'),
        url('assets/fonts/Roboto-Italic.woff') format('woff'),
        url('assets/fonts/Roboto-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('assets/fonts/Roboto-Medium.woff2') format('woff2'),
        url('assets/fonts/Roboto-Medium.woff') format('woff'),
        url('assets/fonts/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('assets/fonts/Roboto-MediumItalic.woff2') format('woff2'),
        url('assets/fonts/Roboto-MediumItalic.woff') format('woff'),
        url('assets/fonts/Roboto-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('assets/fonts/Roboto-Bold.woff2') format('woff2'),
        url('assets/fonts/Roboto-Bold.woff') format('woff'),
        url('assets/fonts/Roboto-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('assets/fonts/Roboto-BoldItalic.woff2') format('woff2'),
        url('assets/fonts/Roboto-BoldItalic.woff') format('woff'),
        url('assets/fonts/Roboto-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('assets/fonts/Roboto-Black.woff2') format('woff2'),
        url('assets/fonts/Roboto-Black.woff') format('woff'),
        url('assets/fonts/Roboto-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('assets/fonts/Roboto-BlackItalic.woff2') format('woff2'),
        url('assets/fonts/Roboto-BlackItalic.woff') format('woff'),
        url('assets/fonts/Roboto-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}
