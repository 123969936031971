@import 'styles/function.scss';

.tabs {
  height: pxToRem(50);
  display: flex;
  align-items: center;
}

.tab {
  user-select: none;
  cursor: pointer;
  height: 100%;

  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  font-weight: bold;
  font-size: pxToRem(16);
  line-height: pxToRem(20);

  color: #666;

  box-shadow: 0px pxToRem(4) pxToRem(4) rgba(0, 0, 0, 0.25);
}

.tabActive {
  color: #2f80ed;
  box-shadow: 0px pxToRem(4) pxToRem(4) rgba(0, 0, 0, 0.25), inset 0 -15px 0px -10px #2f80ed;
}

.tabLabel {
  padding-top: pxToRem(2);
}

.tabIcon {
  width: pxToRem(24);
  height: pxToRem(24);

  flex-shrink: 0;
  margin-right: pxToRem(8);

  path {
    fill: #666666;
  }
}
.tabIconData {
  width: pxToRem(20);
  height: pxToRem(20);
}

.tabIconActive {
  path {
    fill: #2f80ed;
  }
}

.tabIconCondition {
  width: pxToRem(24);
  height: pxToRem(24);
  margin-right: pxToRem(8);

  path {
    fill: white;
  }
}
.tabIconActiveCondition {
  rect {
    fill: #2f80ed;
  }
}


.condition {
  flex-shrink: 0.9;

  svg,
  span {
    flex-shrink: 0;
  }

  .modalTabIcon {
    margin-right: pxToRem(8);
    rect {
      fill: #666;
    }
    path {
      fill: #f1f1f1;
    }
  }

  .modalTabIconActive {
    margin-right: pxToRem(8);
    rect {
      fill: #2f80ed;
    }
    path {
      fill: #f1f1f1;
    }
  }
}

.children {
  height: pxToRem(395);
  overflow-y: auto;
  padding: pxToRem(26) pxToRem(16);
}
