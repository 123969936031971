@import 'styles/function.scss';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: pxToRem(6) 0 pxToRem(6) pxToRem(22);
  min-height: pxToRem(28);
  border-bottom: pxToRem(1) solid #cccccc;
  color: #4d4d4d;
}

.info {
  padding-left: pxToRem(8);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.infoCheckbox {
  margin-right: pxToRem(11);
}

.infoName {
  max-width: pxToRem(400);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: pxToRem(240);
}

.buttons {
  display: flex;
}

.buttons svg,
.buttons img {
  cursor: pointer;
}

.buttonsIcon {
  width: pxToRem(28);
  height: pxToRem(20);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 pxToRem(4) 0 pxToRem(4);
  position: relative;

  & path {
    fill: #4d4d4d;
  }

  &Disabled {
    pointer-events: none;
    opacity: .5;
  }
}

.customTooltip {
  background: #ffffff !important;
  border: 0.5px solid #0066ff !important;
  border-radius: 5px !important;
  width: fit-content !important;
  height: fit-content !important;
  padding: pxToRem(5) pxToRem(5) !important;
  color: #000000 !important;
  font-size: pxToRem(11) !important;
  line-height: pxToRem(11) !important;
  opacity: 1 !important;
  margin-top: pxToRem(20) !important;
  margin-left: 0 !important;

  z-index: 9999999 !important;
}
