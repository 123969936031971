@import 'styles/function.scss';

.notificationsList {
  height: calc(100vh - 207px);
  overflow: auto;

  font-weight: 500;
  font-size: pxToRem(14);
  line-height: pxToRem(16);
  color: #4d4d4e;
}

.buttonBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: pxToRem(16) 0 pxToRem(8) 0;
  padding: 0 pxToRem(8);
}

.searchWrapper {
  min-width: pxToRem(265);
  width: 100%;
}

.notificationCreateButton {
  flex-shrink: 0;
  width: pxToRem(120);
  margin-right: 0;
}

.notificationsButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  height: pxToRem(64);
}

.notificationsButton {
  position: relative;

  right: 0;
  min-width: pxToRem(176);
  margin-right: pxToRem(16);
}
