@import 'styles/function.scss';

.layout {
  padding: pxToRem(16);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  overflow: auto;
}

.container {
  width: 100%;
  height: calc(100vh - #{pxToRem(56)});

  color: #4d4d4e;
  font-size: pxToRem(14);
}

.objects {
  margin-bottom: pxToRem(20);
}

.objectWrap {
  margin-bottom: pxToRem(10);
}

.addTrackObjectButton {
  margin-left: 0 !important;
}

.periodWrap {
  margin-top: pxToRem(20);
  margin-bottom: pxToRem(20);
}

.periodLabel {
  margin: pxToRem(8) pxToRem(10);
}

.periodButtonsGroup {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.periodButton:first-child {
  margin-left: 0 !important;
}

.periodDatePikersContainer {
  display: flex;
}

.datepickerWrapper {
  flex: 1;
  position: relative;

  &:not(:first-child) {
    margin-left: pxToRem(16);
  }
}

.checkWrap {
  display: flex;
  align-items: center;

  margin-bottom: pxToRem(15);
}

.checkLabel {
  margin-left: pxToRem(8);
}

.checkWrapBottom {
  margin-bottom: pxToRem(30);
}

.buttonsGroup {
  display: flex;
  justify-content: flex-end;
  padding-bottom: pxToRem(76);
}

.datePicker {
  margin-top: pxToRem(20);
  width: 100%;
}

.tabInfoDatePickerEmpty .datePickerInput,
.tabInfoDatePickerEmpty .datePickerInput:hover {
  border: pxToRem(1) solid red;
}

.datePickerInput {
  width: 100%;
}

.selectWrapper {
  flex: 1;
  // width: calc(100% - #{pxToRem(95)});
}

.objectFirstRow {
  display: flex;
  align-items: center;
}

.iconWarning {
  position: absolute;
  top: pxToRem(23);
  right: pxToRem(3);
  color: #f44336;
}
