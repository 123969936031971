@import 'styles/function.scss';

.checkbox {
    display: flex;
    align-items: center;
}
.checkboxSpan {
    flex-shrink: 0;
    color: #fff;
    cursor: pointer;
}
.checkboxInput {
    position: relative;
    flex-shrink: 0;
    width: pxToRem(18);
    height: pxToRem(18);
    appearance: none;
    outline: none;
    background: white;
    cursor: pointer;
    border: pxToRem(1) solid #ccc;
    border-radius: pxToRem(4);

    &:checked {
        border: none;
    }
}
.checkboxInput::before {
    content: ' ';
    position: absolute;
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
    transition: all 0.1s;
    background: #0066FF;
    border-radius: pxToRem(4);
}
.checkboxInput:checked::before {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url('../../../assets/img/tick.svg');
    background-repeat: no-repeat;
    background-position: center;
    border: pxToRem(1) solid #ccc;
}
.checkboxInput:indeterminate::before {
    top: pxToRem(4);
    right: pxToRem(4);
    bottom: pxToRem(4);
    left: pxToRem(4);
    border-radius: 0;
}

.disabled {
    pointer-events: none;
    border-color: rgba(#5a5358, .4);
    cursor: default;
    
    &::before {
        background-color: #5a5358;
    }
    & + .checkboxSpan {
        color: #808080;
        cursor: default;
    }
}

.negative {
    position: relative;
    flex-shrink: 0;
    width: pxToRem(18);
    height: pxToRem(18);
    appearance: none;
    outline: none;
    background: rgba(244, 67, 54, 0.7);
    cursor: pointer;
    border: none;
    border-radius: pxToRem(4);

    &::before {      
        position: absolute;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        content: 'X';
        color: #FFFFFF;
        font-weight: bold;
        font-size: pxToRem(12);
        transition: all 0.1s;
        border-radius: pxToRem(4);
        padding-top: pxToRem(2);
    }
}

.defaultCursor {
    cursor: default;
}
