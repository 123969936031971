@import 'styles/function.scss';

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: #4d4d4e;
  height: pxToRem(63);

  padding: 0 pxToRem(16);
  font-weight: 500;
  font-size: pxToRem(20);
  line-height: pxToRem(23);
}

.modalBody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: pxToRem(1) solid rgba(0, 0, 0, 0.12);
  border-top: pxToRem(1) solid rgba(0, 0, 0, 0.12);
  padding: 0 pxToRem(8);
  max-height: pxToRem(640);
}

.modalBodyTable {
  height: pxToRem(370);
  overflow-y: auto;
}

.modalControls {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  height: pxToRem(60);
  padding: 0 pxToRem(8);
}