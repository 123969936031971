@import 'styles/function.scss';

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;

  display: flex;
  flex-direction: column;
  width: pxToRem(370);
  max-height: 50vh;

  background: #f7f7f7;
  box-shadow: 0 0 pxToRem(22) rgba(0, 0, 0, 0.25);
  border-radius: pxToRem(8);

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: pxToRem(20);
    border-bottom: pxToRem(1) solid rgba(0, 0, 0, 0.12);

    &Title {
      font-weight: 500;
      font-size: pxToRem(20);
      color: #4d4d4e;
    }

    &CloseIcon {
      & svg {
        cursor: pointer;

        &:hover {
          transform: scale(1.5);
          transition: 0.2s ease;
        }
      }
    }
  }

  .content {
    overflow-y: auto;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: pxToRem(1) solid rgba(0, 0, 0, 0.12);
    padding: pxToRem(16);
  }
}
