@import 'styles/function.scss';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: pxToRem(14);
  color: #4d4d4d;

  border-bottom: 1px solid #d2d2d2;

  padding: pxToRem(8);
}

.side {
  display: flex;
  align-items: center;

  &:last-child {
    margin-right: pxToRem(10);
  }
}

.checkbox {
  margin-right: pxToRem(10);
}

.columnText {
  user-select: none;
  margin-right: pxToRem(55);

  &:last-child {
    margin-right: 0;
  }
}
