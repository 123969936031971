.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.form {
  flex: 1;
  padding: 1rem 1.5rem;
  overflow: auto;

  // style for every children but not first
  & > *:not(:first-child) {
    margin-top: 1rem;
  }
}
