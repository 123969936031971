@import "../node_modules/normalize.css/normalize.css";
@import 'styles/function.scss';

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #F7F7F7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html, body {
  overflow: hidden;
}

ol, ul {
	list-style: none;
}

svg {
  outline: none;
}

#map {
  width: 100%;
  height: calc(100vh - 86px);
  overflow: hidden;

  .ol-zoom {
    top: 40px;
  }

  .ol-zoom .ol-zoom-out {
    margin-top: 204px;
  }
  .ol-zoomslider {
    background-color: transparent;
    top: 68px;;
    left: pxToRem(16);
    background-color: #999999;
    width: 12px;
    border-radius: 30px;
  }

  .ol-touch .ol-zoom .ol-zoom-out {
    margin-top: 212px;
  }
  .ol-touch .ol-zoomslider {
    top: 2.75em;
  }

  .ol-zoom-in.ol-has-tooltip:hover [role=tooltip],
  .ol-zoom-in.ol-has-tooltip:focus [role=tooltip] {
    top: 3px;
  }

  .ol-zoom-out.ol-has-tooltip:hover [role=tooltip],
  .ol-zoom-out.ol-has-tooltip:focus [role=tooltip] {
    top: 232px;
  }

  .ol-zoom .ol-zoom-out,
  .ol-zoom .ol-zoom-in {
    background-color: transparent;
    font-weight: bold;
  }
  .ol-zoom .ol-zoom-out:hover,
  .ol-zoom .ol-zoom-in:hover,
  .ol-zoom .ol-zoom-out:focus,
  .ol-zoom .ol-zoom-in:focus {
    background-color: transparent;
  }



  .ol-control > *{
    cursor: pointer;
  }

  .ol-zoom.ol-control {
    background-color: transparent;
  }

  .ol-zoomslider:hover {
    background-color: #999999;
  }

  .ol-zoomslider .ol-zoomslider-thumb {
    width: 26px;
    height: 15px;
    left: -(pxToRem(8));
    background-color: #E9E9E9;
    border-radius: 30px;
  }

  .ol-zoomslider .ol-zoomslider-thumb:hover {
    background-color: #E9E9E9;
  }

  .ol-zoomslider .ol-zoomslider-handle {
    background-color: #E9E9E9;
  }
  .ol-zoomslider .ol-zoomslider-handle:hover {
    background-color: #E9E9E9;
  }

  .ol-zoomslider .ol-control button:hover, .ol-control button:focus {
    background-color: #E9E9E9;
  }
}

.ol-control__select {
  display: block;
  margin: pxToRem(1);
  padding: 0;
  color: white;
  font-size: 1em;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  line-height: .4em;
  background-color: rgba(0,60,136,0.5);
  border: none;
  border-radius: pxToRem(2);
  outline: 0;
  height: 1.375em;
  width: 5.375em;
}

.ol-control__select-wrapper {
  top: pxToRem(10);
  left: pxToRem(50);
}

.ol-control__select-wrapper-layer {
  top: pxToRem(10);
  left: pxToRem(185);
}

.ol-control__select-wrapper-marker {
  top: pxToRem(10);
  left: pxToRem(320);
}
.ol-control__select-wrapper-heatmap {
  top: pxToRem(10);
  left: pxToRem(455);
}

.ol-control__select-wrapper-heatmap select {
  width: pxToRem(150);
}
.ol-attribution {
  display: none;
}


::-webkit-scrollbar {
  width: pxToRem(10);
  height: pxToRem(10);
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: pxToRem(5);
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.span--plus:before {
  content: '+';
  margin-right: pxToRem(12);
}

.span--plus:after {
  content: '+';
  margin-left: pxToRem(12);
  visibility: hidden;
}
.span--replacement:after {
  display: none;
}

.test {
  top: pxToRem(10);
  left: pxToRem(330);
}

.test button {
  line-height: 1.15;
  font-size: 1em;
  width: pxToRem(45);
}



.ol-scale-line {
  bottom: .5rem;
}

.ol-mouse-position {
  bottom: .5rem;
  top: auto;
  left: auto;
  right: 10px;
  color: #000000;
	text-shadow:
		-0   -1px 0   #FFFFFF,
		 0   -1px 0   #FFFFFF,
		-0    1px 0   #FFFFFF,
		 0    1px 0   #FFFFFF,
		-1px -0   0   #FFFFFF,
		 1px -0   0   #FFFFFF,
		-1px  0   0   #FFFFFF,
		 1px  0   0   #FFFFFF,
		-1px -1px 0   #FFFFFF,
		 1px -1px 0   #FFFFFF,
		-1px  1px 0   #FFFFFF,
		 1px  1px 0   #FFFFFF,
		-1px -1px 0   #FFFFFF,
		 1px -1px 0   #FFFFFF,
		-1px  1px 0   #FFFFFF,
		 1px  1px 0   #FFFFFF;
}

.select-box {
  display: block;
  margin: pxToRem(1);
  padding: 0;
  color: #FFFFFF;
  font-size: pxToRem(12);
  font-weight: normal;
  text-decoration: none;
  text-align: center;
  line-height: .4em;
  background-color: #0066FF;
  border: none;
  border-radius: pxToRem(40);
  outline: 0;
  height: pxToRem(24);
  width: pxToRem(120);

  &:hover {
    background-color: #0066FF;
  }

  &__current {
    position: relative;
    box-shadow: 0 15px 30px -10px transparentize(#000, 0.9);
    cursor: pointer;
    outline: none;
    width: pxToRem(110);
    height: pxToRem(24);
    display: flex;
    justify-content: center;
    align-items: center;

    &:focus {
      & + .select-box__list {
        opacity: 1;

        // We have to set "animation-name: none;" to make the list visible (read below how it works)

        animation-name: none;

        .select-box__option {
          cursor: pointer;
        }
      }

      .select-box__icon {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  &__icon {
    position: absolute;
    top: pxToRem(10);
    right: pxToRem(0);
    width: pxToRem(10);
    transition: 0.2s ease;
  }

  &__value {
    display: flex;
    justify-content: center;
  }

  &__input {
    display: none;

    &:checked + .select-box__input-text {
      display: flex;
      text-align: left;
    }
  }

  &__input-text {
    display: none;
    // font-size: pxToRem(9);
    color: #fff;
    line-height: pxToRem(10);
    text-align: center;
    vertical-align: middle;
    padding: pxToRem(3) pxToRem(10);
  }

  &__list {
    position: absolute;
    width: pxToRem(100);
    padding: 0;
    top: pxToRem(15);
    margin: pxToRem(9);
    list-style: none;
    opacity: 0;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);

    // We need to use animation with delay.
    // Otherwise the click event will not have time to run on label, because this element disapears immediately when .select-box__current element loses the focus.
    // This delay will not be noticed because we set "opacity" to "0".
    // We also use "animation-fill-mode: forwards" to make the list stay hidden.

    animation-name: HideList;
    animation-duration: 0.5s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: step-start;
    box-shadow: 0 15px 30px -10px transparentize(#000, 0.9);

    & li:last-child label {
      border-radius: 0 0 pxToRem(4) pxToRem(4);
    }
  }

  &__option {
    display: block;
    padding: pxToRem(8);
    line-height: 128%;
    background-color: #fff;
    color: #4D4D4E;
    text-align: left;
    font-weight: normal;

    &:hover,
    &:focus {
      background-color: #E9E9E9;
    }
  }
}
.Toastify__toast-container {
  padding: pxToRem(56) pxToRem(4)!important;
  max-height: 100vh!important;
  z-index: 3!important;
  overflow-y: auto!important;
  overflow-x: hidden!important;
}
.Toastify__toast-container::-webkit-scrollbar {
  width: 0;
}

@keyframes HideList {
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(0);
  }
}

.react-datepicker-popper {
  z-index: 9999;
}


.report-render-error,
.report-render-finish {
  position: absolute;
  z-index: -100;
  opacity: 0;
  visibility: hidden;
}

#routesWrapper .react-datepicker-wrapper {
  width: 100%;
}
