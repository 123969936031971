@import 'styles/function.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.content {
  height: calc(100vh - 240px);
  overflow: hidden auto;
  padding: pxToRem(16) pxToRem(24);
}

.row {
  margin-bottom: pxToRem(16);

  &:last-child {
    margin-bottom: 0;
  }

  input {
    width: 100%;
  }
}

.rowMultipleSelectOptions {
  font-size: pxToRem(14);
  max-height: pxToRem(200);
}

.rowSelect {
  input {
    width: calc(100% - 24px);
  }
}

.rowDropButton {
  width: pxToRem(120);
  margin-left: auto;

  button {
    margin: 0;
  }
}

.description {
  font-size: pxToRem(14);
  font-weight: bold;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  padding: pxToRem(16) 0;
}
