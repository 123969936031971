@import 'styles/function.scss';
$borderRadius: 8;

.wrapper {
  width: pxToRem(152);
  height: pxToRem(185);

  border: pxToRem(1) solid #9C9C9C;
  border-radius: pxToRem($borderRadius);
  position: relative;
}

.header {
  user-select: none;

  font-size: pxToRem(10);
  line-height: pxToRem(20);
  color: #666;
  text-align: center;

  position: absolute;
  left: 0;
  right: 0;
  bottom: 100%;
}

.img {
  user-select: none;

  width: 100%;
  height: 100%;

  object-fit: contain;
}

.content {
  display: flex;
  align-items: center;

  height: 100%;

  background: #fff;
  border-radius: pxToRem($borderRadius);
}

.closeBtn {
  position: absolute;
  cursor: pointer;

  top: pxToRem(10);
  right: pxToRem(10);

  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: scale(0.9);
  }
}

.inputFile {
  display: none;
}

.inputFileLabel {
  cursor: pointer;

  width: 100%;
  height: 100%;
}

.inputFileLabelDefault {
  padding: pxToRem(10);
}
