@import 'styles/function.scss';
@import 'styles/mixins.scss';

.list {
  font-size: pxToRem(14);
  color: #4d4d4d;

  flex: 1 0 59%;
  overflow-y: auto;
}

.item {
  padding: pxToRem(8);

  border-bottom: 1px solid #D2D2D2;

  &:last-child {
    border-bottom: none;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.side {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  margin-right: pxToRem(10);

  &:last-child {
    margin-right: 0;
  }
}

.checkbox {
  margin-right: pxToRem(10);
}

.name {
  cursor: pointer;
  color: #0066FF;
  text-decoration: underline;
  font-weight: bold;
}

.type {
  font-size: pxToRem(12);
  text-align: center;

  border: 1px solid black;
  border-radius: pxToRem(40);

  min-width: pxToRem(90);

  padding: pxToRem(2) 0;
  margin-right: pxToRem(16);
}

.typeIncoming {
  $col:#00BF78;

  color: $col;
  border-color: $col;
}

.typeOutgoing {
  $col:#FF8F00;

  color: $col;
  border-color: $col;
}

.deleteIcon {
  margin: 0 pxToRem(16);
}

.icon {
  cursor: pointer;

  width: pxToRem(24);
  height: pxToRem(24);
}

.footer {
  font-size: pxToRem(12);
  margin-left: pxToRem(30);
}

.footerRow {
  margin-bottom: pxToRem(2);

  &:last-child {
    margin-bottom: 0;
  }
}

.footerRowDescription {
  font-weight: bold;
}

.customTooltip {
  @include inc-weight-selector() {
    background: #ffffff;
    border: 0.5px solid #0066ff;
    border-radius: 5px;
    width: fit-content;
    height: fit-content;
    padding: pxToRem(5) pxToRem(5);
    color: #000000;
    font-size: pxToRem(11);
    line-height: pxToRem(11);
    opacity: 1;
    margin-top: pxToRem(20);
    margin-left: 0;

    z-index: 9999999;
  }
}
