@import 'styles/function.scss';

.tooltip {
  cursor: pointer;
  margin-left: pxToRem(5);

  svg path {
    fill:rgba(102, 102, 102, .5);
  }
}

.customTooltip {
  background: #ffffff !important;
  border-radius: 8px !important;
  width: 288px !important;
  height: fit-content !important;
  padding: pxToRem(5) pxToRem(5) !important;
  color: #000000 !important;
  font-size: pxToRem(14) !important;
  line-height: pxToRem(18) !important;
  opacity: 1 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;

  z-index: 9999999 !important;
}
