@import 'styles/function.scss';

.fieldWrap {
  border: pxToRem(1) solid #666666;
  border-radius: pxToRem(4);
  width: pxToRem(383);
  padding: pxToRem(8) pxToRem(15);

  display: flex;
  align-items: center;
  justify-content: space-around;
}

.twoLines {
  cursor: pointer;
}

.inputsWrap {
  display: flex;
  align-self: stretch;
  flex-direction: column;
  justify-content: space-around;
}

.label {
  width: 20em;
  background-color: transparent;

  border: 0;
  outline: 0;

  font-size: pxToRem(10);
  color: #666666;
}
.label::placeholder {
  color: #666666;
}

.input {
  width: 20em;
  font-size: pxToRem(14);

  padding-top: 1px;
  padding-bottom: 1px;

  border: 0;
  outline: 0;

  background-color: transparent;

  &::placeholder {
    color: rgba(0, 0, 0, 0.2);
  }
}

.eye {
  cursor: pointer;
}
