@import 'styles/function.scss';

.contentControls {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: pxToRem(16) 0 pxToRem(8) 0;
  padding: 0 pxToRem(8);
}

.contentSearch {
  width: 100%;
}

.contentCreate {
  button {
    margin-right: 0;
    width: pxToRem(120);
  }
}
