@import 'styles/function.scss';

.closeModalIcon {
  cursor: pointer;

  transition: transform .25s;

  &:hover {
    transform: scale(1.5);
  }
}
