@import 'styles/function.scss';

.container {
    padding: pxToRem(10) pxToRem(20);
}

.containerClearPadding {
    padding-left: 0;
}

.label {
    font-size: pxToRem(14);
    line-height: pxToRem(16);
    margin: pxToRem(10);
    margin-bottom: pxToRem(6);
}

.colorsList {
    display: flex;
    align-items: center;
}

.itemWrap {
    margin: pxToRem(1);
    border: pxToRem(3) solid transparent;
    border-radius: pxToRem(5);

    &Active {
        border-color: #000000;
    }
}

.colorItem {
    border-radius: pxToRem(4);
    width: pxToRem(16);
    height: pxToRem(16);
    border: pxToRem(1) solid #CCCCCC;
    cursor: pointer;

    &Active {
        border: pxToRem(3) solid #000000;
        border-radius: pxToRem(5);
    }
}

