ul {
  li.react-datepicker__time-list-item--disabled {
    display: none;
  }
}

.popperDatePicker {
  .react-datepicker__triangle {
    left: 80% !important;
  }
}
