@import 'styles/function.scss';

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: pxToRem(63);

  padding: 0 pxToRem(16);
  font-weight: 500;
  font-size: pxToRem(20);
  line-height: pxToRem(23);
}

.modalBody {
  height: pxToRem(500);
  border-bottom: pxToRem(1) solid rgba(0, 0, 0, 0.12);
  border-top: pxToRem(1) solid rgba(0, 0, 0, 0.12);
}

.tabContent {
  height: 100%;
}

.modalControls {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  height: pxToRem(60);
  padding: 0 pxToRem(8);
}

.modalContent {
  padding: pxToRem(16);
  font-size: pxToRem(14);
}


.customTooltip {
  background: #ffffff !important;
  border: 0.5px solid #0066ff !important;
  border-radius: 5px !important;
  width: fit-content !important;
  height: fit-content !important;
  padding: pxToRem(5) pxToRem(5) !important;
  color: #000000 !important;
  font-size: pxToRem(11) !important;
  line-height: pxToRem(11) !important;
  opacity: 1 !important;
  margin-top: pxToRem(20) !important;
  margin-left: 0 !important;

  z-index: 9999999 !important;
}
