@import 'styles/function.scss';

.wrapper {
  height: pxToRem(205);
  overflow-y: auto;

  border-bottom: 1px solid #000;

  padding: pxToRem(16);
}

.row {
  margin-bottom: pxToRem(8);

  &:last-child {
    margin-bottom: 0;
  }
}
