@import 'styles/function.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: pxToRem(30);
}

.title {
  user-select: none;
  font-size: pxToRem(18);
  color: #4d4d4e;
  margin-bottom: pxToRem(20);
}

.showExampleBlock {
  border: pxToRem(1) solid #4d4d4e;
  box-sizing: border-box;
  border-radius: pxToRem(4);

  width: pxToRem(300);
  padding: pxToRem(16) pxToRem(10) pxToRem(10) pxToRem(20);

  margin-bottom: pxToRem(14);

  &Row {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    width: pxToRem(370);

    padding-right: 0;
    padding-left: 0;

    div[class*="personIcon"] {
      align-items: flex-start;
      padding-left: pxToRem(14);
    }

    &HasBackground {
      padding-top: pxToRem(16);
    }
  }

  .personIcon {
    display: flex;
    align-items: center;
    padding-left: pxToRem(30);
    margin-bottom: pxToRem(12);

    &Info {
      cursor: pointer;
      margin-left: auto;
    }
  }
}

.checkboxesBlock {
  width: pxToRem(270);
  padding: pxToRem(10) pxToRem(10) 0 pxToRem(20);
}

%hasBg {
  $offsetTB: pxToRem(-6);
  $offsetLR: pxToRem(-4);

  display: block;
  
  position: absolute;
  top: $offsetTB;
  left: $offsetLR;
  right: $offsetLR;
  bottom: $offsetTB;

  background-color: #0066ff;
  z-index: -1;
}

.checkboxGroup {
  display: flex;
  flex-direction: column;

  .checkbox {
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;

    margin-bottom: pxToRem(4);

    &Title {
      position: relative;
      font-size: pxToRem(14);
      width: 100%;
      margin: auto pxToRem(14);

      &HasBackground {
        color: #DADADA;
        z-index: 0;

        &::after {
          content: '';
          @extend %hasBg;
        }        

        &BottomRadius::after {
          border-bottom-left-radius: pxToRem(5);
          border-bottom-right-radius: pxToRem(5);
        }
      }
    }

    &BoldBlueTitle {
      position: relative;
      font-weight: bold;
      color: #0066ff;
      width: 100%;
      margin: auto pxToRem(14);

      &HasBackground {
        color: white;
        z-index: 0;

        &::after {
          content: '';
          @extend %hasBg;
        }        

        &TopRadius::after {
          border-top-left-radius: pxToRem(5);
          border-top-right-radius: pxToRem(5);
        }
      }
    }

    &Info {
      flex-shrink: 0;
      margin-left: auto;
    }
  }
}

.customTooltip {
  background: #ffffff !important;
  border-radius: 8px !important;
  width: 288px !important;
  height: fit-content !important;
  padding: pxToRem(5) pxToRem(5) !important;
  color: #000000 !important;
  font-weight: bold !important;
  font-size: pxToRem(14) !important;
  line-height: pxToRem(18) !important;
  opacity: 1 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;

  z-index: 9999999 !important;
}
