@import 'styles/function.scss';

.timeWrapper {
  user-select: none;
  position: relative;

  border-left: 2px solid white;

  font-size: pxToRem(11);
  line-height: pxToRem(28);

  padding: 0 pxToRem(7) 0 pxToRem(11);
}
