@import 'styles/function.scss';

.container {
  width: pxToRem(12);
  height: pxToRem(12);

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #ffffff;
  border: pxToRem(1) solid #4d4d4e;

  cursor: pointer;
  user-select: none;
}
