@import 'styles/function.scss';

.unitWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-weight: 500;
  font-size: pxToRem(14);
  line-height: pxToRem(16);
  color: #4d4d4e;
  height: pxToRem(40);
  border-bottom: pxToRem(1) solid black;
}

.nameHead {
  margin-left: pxToRem(20);
}

.unitInfo {
  padding-left: pxToRem(8);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.unitInfoExpand {
  margin-right: pxToRem(10);
}

.headBlockIcon {
  margin-left: pxToRem(10);
  cursor: pointer;
}

