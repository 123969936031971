@import 'styles/function.scss';

.configItemContainer {
  display: flex;

  font-size: pxToRem(14);
  color: #4d4d4e;

  z-index: 5;

  &:not(:first-child) {
    margin-top: pxToRem(12);
  }
}

.configItem {
  display: flex;
  align-items: center;
  flex: 1;

  padding: pxToRem(12) pxToRem(8);
  border: pxToRem(1) solid #666666;
  border-radius: pxToRem(4);
}

.dragIcon {
  flex: 0 0 auto;
  margin-left: pxToRem(10);

  cursor: pointer;
}

.configItemLabel {
  margin-left: pxToRem(10);
}

.arrowContainer {
  display: flex;
  align-items: center;
  padding: 0 0.5rem;

  cursor: pointer;
}
