@import 'styles/function.scss';

.radioButton {
  display: flex;
  align-items: center;
}

.radioButtonInput {
  cursor: pointer;
  position: relative;
  flex-shrink: 0;
  width: pxToRem(18);
  height: pxToRem(18);
  appearance: none;
  outline: none;
  background: white;
  border: pxToRem(1) solid #ccc;
  border-radius: 50%;
  
  &:checked {
    border-color: #0066ff;
  }
}
.radioButtonInput::before {
  content: '';
  position: absolute;
  top: 50%;
  right: 50%;
  bottom: 50%;
  left: 50%;
  background: #0066ff;
  border-radius: pxToRem(4);
  width: 0;
  height: 0;
  transition-property: width, height;
  transition-duration: 0.1s, 0.1s;
}
.radioButtonInput:checked::before {
  border: pxToRem(1) solid #0066ff;
  border-radius: 50%;
  width: pxToRem(7);
  height: pxToRem(7);
  transform: translate(-50%, -50%);
}

.radioButtonInput {
  &.disabled {
    border-color: #5a5358;
    cursor: default;

    &::before {
      border: pxToRem(1) solid #5a5358;
      background-color: #5a5358;
    }
    & + .radioButtonSpan {
      color: #808080;
      cursor: default;
    }
  }
}

.negative {
  cursor: pointer;
  position: relative;
  flex-shrink: 0;
  width: pxToRem(18);
  height: pxToRem(18);
  appearance: none;
  outline: none;
  border-radius: 50%;
  border: pxToRem(1) solid rgba(244, 67, 54, 0.7);

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: rgba(244, 67, 54, 0.7);
    border: pxToRem(1) solid rgba(244, 67, 54, 0.7);
    border-radius: 50%;
    width: pxToRem(7);
    height: pxToRem(7);
    transform: translate(-50%, -50%);
  }
}

.defaultCursor {
  cursor: default;
}
