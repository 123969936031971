@import 'styles/function.scss';

.main {
  cursor: pointer;
  text-align: center;
  outline: none;
  font-size: 1em;
  line-height: 1em;

  background-color: white;

  border: pxToRem(1) solid #d2d2d2;
  border-radius: pxToRem(30);

  padding: pxToRem(8) pxToRem(12);
}

.rounded {
  border-radius: 50%;
  padding: pxToRem(4);
  text-align: center;
}

.downed {
  background-color: #0066ff;
  border: pxToRem(1) solid transparent;

  color: #fff;
}

.disabled {
  pointer-events: none;
  user-select: none; 

  background: #ccc;
  border: pxToRem(1) solid transparent;

  color: #fff;
}