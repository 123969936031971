@import 'styles/function.scss';

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: pxToRem(63);

  padding: 0 pxToRem(24);
  font-weight: 500;
  font-size: pxToRem(20);
  line-height: pxToRem(23);
}

.closeModalIcon {
  cursor: pointer;

  &:hover {
    transform: scale(1.5);
  }
}

.modalBodyDelete {
  height: pxToRem(180);
  border-bottom: pxToRem(1) solid rgba(0, 0, 0, 0.12);
  border-top: pxToRem(1) solid rgba(0, 0, 0, 0.12);
}

.modalBodyDeleteText {
  font-size: pxToRem(14);
  padding: 0 pxToRem(24);
}

.modalBodyDeleteLabel {
  font-weight: 500;
  padding: pxToRem(15) pxToRem(30);
}

.modalControls {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  height: pxToRem(56);
  padding: 0 pxToRem(24);

  &Btn {
    width: pxToRem(64);
  }
}
