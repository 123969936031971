@import 'styles/function.scss';

.notificationItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: pxToRem(6) 0;
  min-height: pxToRem(28);
  border-bottom: pxToRem(1) solid #cccccc;
  color: #4d4d4d;

  &Info {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    margin: 0 pxToRem(8);
  }

  &Expand {
    margin-right: pxToRem(7);
  }
  &Checkbox {
    margin-right: pxToRem(11);
  }
}

.notificationButtons {
  display: flex;
}

.notificationButtons svg,
.notificationButtons img {
  cursor: pointer;
}

.notificationButtonsIcon {
  width: pxToRem(28);
  height: pxToRem(20);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 pxToRem(6) 0 pxToRem(6);
  position: relative;

  & path {
    fill: #4d4d4d;
  }

  &Disabled {
    pointer-events: none;
    opacity: .5;
  }
}

.customTooltip {
  background: #ffffff !important;
  border: 0.5px solid #0066ff !important;
  border-radius: 5px !important;
  width: fit-content !important;
  height: fit-content !important;
  padding: pxToRem(5) pxToRem(5) !important;
  color: #000000 !important;
  font-size: pxToRem(11) !important;
  line-height: pxToRem(11) !important;
  opacity: 1 !important;
  margin-top: pxToRem(20) !important;
  margin-left: 0 !important;

  z-index: 9999999 !important;
}
