@import 'styles/function.scss';

.popupHeader {
  display: flex;
  align-items: center;

  height: pxToRem(40);
}

.popupHeaderName {
  display: flex;
  align-items: center;
}

.popupHeaderIcon {
  width: pxToRem(26);
  height: pxToRem(26);
  margin-right: pxToRem(8);
}

.popupHeaderTitle {
  display: flex;
  flex-direction: column;
  color: #ff8f00;

  margin: 0;

  h4 {
    font-weight: normal;
    margin: 0;
  }
}

.popupHeaderClose {
  cursor: pointer;
  width: pxToRem(24);
  height: pxToRem(24);
  margin-right: pxToRem(16);

  svg {
    width: 100%;
    height: 100%;
    opacity: 0.7;
  }

  &:hover {
    transform: scale(1.3);
    transition: 0.2s ease;
  }
}

.popupContentBtns {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  border-top: 1px solid #ccc;

  padding: pxToRem(15) 0;
}

.popupContentBtnsMaxWidth {
  max-width: pxToRem(91);
}

.popupInfo {
  display: flex;
  flex-wrap: nowrap;

  border-bottom: 1px solid #ccc;

  padding: pxToRem(11) pxToRem(8);
}

.popupInfoIcon {
  flex-shrink: 0;

  width: pxToRem(94);
  height: pxToRem(128);

  border-radius: pxToRem(8);
  border: 1px solid #ccc;
  overflow: hidden;

  margin-right: pxToRem(15);
}

.popupInfoIconImage {
  height: pxToRem(112);
}

.popupInfoIconPhoto {
  width: 100%;
  height: 100%;
  object-fit: none;
}

.popupInfoIconNumber {
  height: pxToRem(16);
  background-color: #0066ff;
  color: white;
  text-align: center;
  bottom: 0;
}

.popupInfoContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  font-size: pxToRem(14);

  max-height: pxToRem(130);
  overflow-y: auto;
}

.popupInfoContentData {
  margin-bottom: pxToRem(5);

  &:last-child {
    margin-bottom: 0;
  }

  div {
    display: flex;
    align-items: center;
    margin-bottom: pxToRem(5);
    border-left: 1px solid #4d4d4e;
    padding-left: pxToRem(4);

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.popupInfoContentDataName,
.popupInfoContentDataPosition {
  color: black;
}

.popupInfoContentDataNumber {
  color: #0066ff;
}

.popupDescription {
  height: pxToRem(85);
  overflow-y: auto;
  font-size: pxToRem(14);

  padding: pxToRem(11) pxToRem(8);
}

.popupDescriptionTitle {
  font-size: pxToRem(14);
  font-weight: bold;

  color: #ff8f00;

  margin: 0;
  margin-bottom: pxToRem(6);
}

.popupDescriptionText {
  position: relative;

  left: pxToRem(4);
  line-height: 138%;
  border-left: 1px solid #4d4d4e;

  padding-left: pxToRem(4);
}

.customContentStyles {
  padding: 0;
}
