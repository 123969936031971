@import 'styles/function.scss';

.wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(77, 77, 78, 0.6);
  z-index: 9999;
}

.container {
  display: flex;
  flex-direction: column;

  width: pxToRem(400);
  min-height: pxToRem(100);

  color: #4D4D4E;

  border-radius: pxToRem(10);
  background-color: #f1f1f1;
}

.header, .content, .footer {
  padding: 0 pxToRem(16);
}

.header, .footer {
  flex-shrink: 0;
  min-height: pxToRem(50);
}

.content {
  flex: 1 1 auto;

  max-height: 50vh;
  overflow-y: auto;

  border-bottom: pxToRem(1) solid rgba(0, 0, 0, 0.12);
  border-top: pxToRem(1) solid rgba(0, 0, 0, 0.12);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerClose {
  align-self: flex-start;
  cursor: pointer;

  width: pxToRem(24);
  height: pxToRem(24);

  margin-top: pxToRem(12);

  transition: transform .25s;

  &:hover {
    transform: scale(1.2);
  }
}

.footer {
  display: flex;
  align-items: center;
}
