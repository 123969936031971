@import 'styles/function.scss';

.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: pxToRem(16) pxToRem(8);
}

.controlsSearch {
  width: 100%;
}

.controlsCreate {
  button {
    margin-right: 0;
    width: pxToRem(120);
  }
}

.header {
  border-bottom: pxToRem(1) solid #000;

  padding: pxToRem(8);
  padding-top: 0;
}

.title {
  margin: 0;
}
