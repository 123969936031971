@import 'styles/function.scss';

.wrapper {
  position: relative;
}

.input {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  width: pxToRem(0.1);
  height: pxToRem(0.1);
  z-index: -10;
}