@import 'styles/function.scss';

.popupContentNoPaddings {
  height: 100%;
  padding: 0;
}

.popupSpinner {
  height: pxToRem(150);

  margin: pxToRem(-40) 0;
}

.popupList {
  width: 100%;
  height: 100%;

  overflow-y: auto;
  overflow-x: hidden;
}

.popupListItem {
  cursor: pointer;

  display: flex;
  align-items: center;

  min-height: pxToRem(33);
  border-bottom: 1px solid #CCCCCC;

  padding: 0 pxToRem(8);

  &:hover {
    background-color: #E9E9E9;
  }
}

.popupListItemDate {
  font-size: pxToRem(12);
  color: #4D4D4E;

  margin-right: pxToRem(8);
}

.popupListItemDescription {
  display: flex;
  align-items: center;

  font-size: pxToRem(12);
  color: #0066FF;
}

.popupListItemDescriptionIcon {
  flex-shrink: 0;

  width: pxToRem(18);
  height: pxToRem(18);

  margin-right: pxToRem(8);

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.popupListItemDescriptionText {
  padding: pxToRem(4) 0;
}
