
@import 'styles/function.scss';

.container {
    position: absolute;
    top: pxToRem(20);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: pxToRem(50);
    width: pxToRem(400);
}

.containerWithHeat {
  width: pxToRem(390);
}

.selectBox {
  display: block;
  margin: pxToRem(1);
  padding: 0;
  color: #FFFFFF;
  font-size: pxToRem(12);
  font-weight: normal;
  text-decoration: none;
  text-align: center;
  line-height: .4em;
  background-color: #0066FF;
  border: none;
  border-radius: pxToRem(40);
  outline: 0;
  height: pxToRem(24);
  width: pxToRem(120);

  &:hover {
    background-color: #0066FF;
  }
}

.selectBoxCurrent {
  position: relative;
  box-shadow: 0 15px 30px -10px transparentize(#000, 0.9);
  cursor: pointer;
  outline: none;
  width: pxToRem(110);
  height: pxToRem(24);
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    & + .selectBoxList {
      opacity: 1;

      // We have to set "animation-name: none;" to make the list visible (read below how it works)

      animation-name: none;

      .selectBoxOption {
        cursor: pointer;
      }
    }

    .selectBoxIcon {
      transform: translateY(-50%) rotate(180deg);
    }
  }
}

.selectBoxIcon {
  position: absolute;
  top: pxToRem(10);
  right: pxToRem(0);
  width: pxToRem(10);
  transition: 0.2s ease;
}

.selectBoxValue {
  display: flex;
  justify-content: center;
}

.selectBoxInput {
  display: none;

  &:checked + .selectBoxInputText {
    display: flex;
    text-align: left;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }
}

.selectBoxInputText {
  display: none;
  // font-size: pxToRem(9);
  color: #fff;
  line-height: pxToRem(10);
  text-align: center;
  vertical-align: middle;
  padding: pxToRem(3) pxToRem(10);
}

.selectBoxList {
  position: absolute;
  width: pxToRem(100);
  padding: 0;
  top: pxToRem(15);
  margin: pxToRem(9);
  list-style: none;
  opacity: 0;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);

  // We need to use animation with delay.
  // Otherwise the click event will not have time to run on label, because this element disapears immediately when .select-box__current element loses the focus.
  // This delay will not be noticed because we set "opacity" to "0".
  // We also use "animation-fill-mode: forwards" to make the list stay hidden.

  animation-name: HideList;
  animation-duration: 0.5s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: step-start;
  box-shadow: 0 15px 30px -10px transparentize(#000, 0.9);

  & li:last-child label {
    border-radius: 0 0 pxToRem(4) pxToRem(4);
  }
}

.selectBoxOption {
  display: block;
  padding: pxToRem(8);
  line-height: 128%;
  background-color: #fff;
  color: #4D4D4E;
  text-align: left;
  font-weight: normal;

  &:hover,
  &:focus {
    background-color: #E9E9E9;
  }
}

  @keyframes HideList {
    from {
      transform: scaleY(1);
    }
    to {
      transform: scaleY(0);
    }
  }
