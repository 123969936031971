@import 'styles/function.scss';

.modalTabs {
    height: pxToRem(54);
    display: flex;
    align-items: center;
}

.modalTab {
    cursor: pointer;
    height: 100%;

    display: flex;
    flex: 1 100%;
    align-items: center;
    justify-content: center;

    font-weight: bold;
    font-size: pxToRem(16);
    line-height: pxToRem(20);

    color: #666;

    box-shadow: 0px pxToRem(4) pxToRem(4) rgba(0, 0, 0, 0.25);
}
.modalTabLabel {
    margin: pxToRem(10);
    padding-top: pxToRem(2);
}

.modalTabActive {
    color: #2F80ED;
    box-shadow: 0px pxToRem(4) pxToRem(4) rgba(0, 0, 0, 0.25), inset  0 -15px 0px -10px #2F80ED
}

.modalTab:last-child {
    border-right: 0
}

.modalTabIcon path {
    fill: #666666; 
}

.modalTabIconActive path {
    fill: #2F80ED;
}

