@import 'styles/function.scss';

.popupContainer {
  width: pxToRem(400);
  position: fixed;
  background-color: #ffffff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: pxToRem(15);
  border-collapse: collapse;
  border: 1px solid #cccccc;
  font-size: pxToRem(14);
}

.popupHeader {
  display: flex;
  align-items: center;

  font-size: pxToRem(14);
  font-weight: 500;

  &__icon {
    width: pxToRem(24);
    height: pxToRem(20);

    margin-right: pxToRem(8);
  }
}

.popupMain {
  display: flex;
  position: relative;

  .popupButtons {
    position: absolute;
    width: pxToRem(50);
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    & svg {
      cursor: pointer;

      &:hover {
        transform: scale(1.5);
        transition: 0.2s ease;
      }
    }
  }
}

.popupSide {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: pxToRem(15);

  &Photo {
    width: pxToRem(94);
    height: pxToRem(128);
    box-sizing: border-box;
    border: pxToRem(1) solid #9c9c9c;
    border-radius: pxToRem(8);
    background: #ffffff;
    padding-top: pxToRem(5);

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    margin-bottom: pxToRem(15);

    &Image {
      width: 100%;
      height: calc(100% - 22px);
      object-fit: contain;
    }

    &Tracker {
      position: absolute;
      width: 100%;
      bottom: 0;

      background-color: #0066ff;
      text-align: center;
      color: #ffffff;
      padding: pxToRem(2);
      border-radius: 0 0 pxToRem(7) pxToRem(7);
    }

    &Normal {
      border: pxToRem(3) solid #00bf78;
    }
    &Warn {
      border: pxToRem(3) solid #ff8f00;
    }
    &Bad {
      border: pxToRem(3) solid #f44336;
    }
  }

  &Button {
    width: pxToRem(94);
    height: pxToRem(24);
    border: pxToRem(1) solid #d2d2d2;
    border-radius: pxToRem(40);

    display: flex;
    align-items: center;
    justify-content: space-around;

    cursor: pointer;

    & svg path {
      fill: #666666;
    }

    &Text {
      color: rgba(0, 0, 0, 0.6);
      font-size: pxToRem(11);
    }
    &IconDown {
      transform: rotate(0deg);
      animation-duration: 0.5s;
      transition: 0.2s ease;
    }
    &IconUp {
      transform: rotate(-180deg);
      animation-duration: 0.5s;
      transition: 0.2s ease;
    }
  }
}

.popupContent {
  display: flex;
  flex-direction: column;
  font-size: pxToRem(14);
  color: #000000;
  flex: 3;

  &NameWrap {
    margin-bottom: pxToRem(12);
  }

  &Name {
    border-left: pxToRem(1) solid #4d4d4e;
    margin-bottom: pxToRem(5);
    padding-left: pxToRem(4);
  }

  &Position {
    border-left: pxToRem(1) solid #4d4d4e;
    margin-bottom: pxToRem(5);
    padding-left: pxToRem(4);
  }

  &StartYear {
    border-left: pxToRem(1) solid #4d4d4e;
    padding-left: pxToRem(4);
  }

  &OrgWrap {
    margin-bottom: pxToRem(12);
    color: #4d4d4e;
  }

  &Org {
    border-left: pxToRem(1) solid #4d4d4e;
    margin-bottom: pxToRem(5);
    padding-left: pxToRem(4);
  }

  &Structure {
    border-left: pxToRem(1) solid #4d4d4e;
    margin-bottom: pxToRem(5);
    padding-left: pxToRem(4);
  }

  &WorkObject {
    border-left: pxToRem(1) solid #4d4d4e;
    padding-left: pxToRem(4);
    font-size: pxToRem(12);
  }

  &DatesWrap {
    border-left: pxToRem(1) solid #4d4d4e;
    padding-left: pxToRem(4);
    color: #4d4d4e;
  }

  &PrevDate {
    color: #4d4d4e;
  }

  &NextDate {
    color: #4d4d4e;

    &Bad {
      color: #f44336;
      font-weight: bold;
    }
    &Warning {
      color: #ff8f00;
      font-weight: bold;
    }
  }

  &State {
    font-weight: bold;

    &Normal {
      color: #00bf78;
    }
    &Bad {
      color: #f44336;
    }
    &Warning {
      color: #ff8f00;
    }
  }
}

.popupAdditional {
  font-size: pxToRem(14);
}

.popupAdditionalHide {
  display: none;
}

.popupAdditionalShow {
  display: block;
}

.popupAdditionalWrap {
  margin-bottom: pxToRem(8);
}

.popupAdditionalLabel {
  color: #666666;
  font-size: pxToRem(10);
}

.popupAdditionalValue {
  color: #4d4d4e;
  padding-left: pxToRem(5);
  border-left: pxToRem(1) solid #4d4d4e;
}

.popupAdditionalRef {
  color: #0066ff;
  cursor: pointer;
}

.normalState {
  color: #00bf78;
}
.badState {
  color: #f44336;
}
.warningState {
  color: #ff8f00;
}
