@import 'styles/function.scss';

.wrapper {
  user-select: none;
  position: absolute;

  display: flex;
  flex-direction: column;

  bottom: pxToRem(30);
  width: 100%;
  height: 50%;

  right: 0;
  bottom: 0;

  color: #4d4d4e;
  border-left: 1px solid #d7d7d7;

  background-color: #f7f7f7;
  will-change: transform;

  z-index: 1;
}

// header

.header {
  position: relative;

  display: flex;
  flex-direction: column;
}

.headerWideButton {
  user-select: none;
  cursor: pointer;

  display: flex;
  align-items: center;

  width: 100%;
  height: pxToRem(30);

  background-color: #dee8f8;
  border-bottom: 1px solid #ccc;

  padding: pxToRem(8);

  &:hover {
    background-color: #eaf1fb;

    .headerArrow {
      transform: scale(1.2);
    }
    .headerArrowFlip {
      transform: rotate(180deg) scale(1.2);
    }
  }
}

.headerWideButtonUp {
  position: absolute;
  bottom: 100%;
  width: 100%;
}

.headerWideButtonHide {
  display: none;
}

.headerArrow {
  position: relative;

  width: pxToRem(14);
  height: pxToRem(14);

  background-color: #828282;
  border-radius: 50%;

  margin: 0 pxToRem(8);

  transition: transform 0.25s;

  &:before,
  &:after {
    content: '';
    position: absolute;

    background-color: white;

    width: 40%;
    height: pxToRem(1.2);

    top: 50%;
  }

  &:before {
    transform: translate(50%, -50%) rotate(45deg);
    left: 0;
  }

  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
    right: 0;
  }
}

.headerArrowFlip {
  transform: rotate(180deg);
}

.headerText {
  font-size: pxToRem(18);
}

.separator {
  cursor: ns-resize;
  position: relative;
  background-color: #c4c4c4;
  height: pxToRem(10);

  &:before {
    content: '';
    position: absolute;

    background-color: #4d4d4e;
    width: pxToRem(25);
    height: pxToRem(4);

    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    background-color: #e3e3eb;
  }
}

// content

.contentContainer {
  flex-grow: 1;
  overflow-y: auto;

  padding: pxToRem(8);
}

.contentPartlyHide {
  pointer-events: none;
  opacity: 0.7;
}

.content {
  height: 100%;
}

.contentHide {
  display: none;
}

.embed {
  width: 100%;
  height: 100%;
}

// footer

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  border-top: 1px solid #ccc;

  width: 100%;
  bottom: 0;
  background-color: inherit;

  padding: pxToRem(8) 0;
}

.footerBtn {
  text-align: center;

  max-width: pxToRem(90);
  word-break: break-all;

  margin: 0 pxToRem(8);

  &:last-child {
    margin-right: pxToRem(16);
  }
}

.table {
  display: flex;
  flex-wrap: nowrap;
  column-gap: pxToRem(2);

  font-size: pxToRem(14);
  color: #4d4d4e;

  padding: pxToRem(10);
}

.tableWithMargin {
  margin-bottom: pxToRem(30);
}

.tableColumn {
  flex-shrink: 0;
  min-width: pxToRem(200);
}

.tableColumnCell {
  display: flex;
  align-items: center;

  background-color: #e9e9e9;

  padding: pxToRem(10);

  margin-bottom: pxToRem(4);
}

.tableColumnCellHeader {
  font-size: pxToRem(16);
  height: pxToRem(50);

  justify-content: center;
  text-overflow: ellipsis;

  font-weight: bold;
  background-color: #C4C4C4;
}

.noDataTextWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
}
