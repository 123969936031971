@import 'styles/function.scss';

.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: pxToRem(40);
    border-bottom: pxToRem(1) solid black;
}

.info {
  padding-left: pxToRem(8);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.infoExpand {
  margin-right: pxToRem(7);
}

.infoNameHead {
  cursor: pointer;
  user-select: none;
  margin-left: pxToRem(11);
  color: black;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: pxToRem(30);

  & path {
    fill: black;
  }

  svg, span {
    cursor: pointer;
  }
}

.buttonsIcon {
  width: pxToRem(24);
  height: pxToRem(24);
  margin: 0 pxToRem(10);

  &Disabled {
    pointer-events: none;
    opacity: .5;
  }
}

.customTooltip {
  background: #ffffff !important;
  border: 0.5px solid #0066ff !important;
  border-radius: 5px !important;
  width: fit-content !important;
  height: fit-content !important;
  padding: pxToRem(5) pxToRem(5) !important;
  color: #000000 !important;
  font-size: pxToRem(11) !important;
  line-height: pxToRem(11) !important;
  opacity: 1 !important;
  margin-top: pxToRem(20) !important;
  margin-left: 0 !important;

  z-index: 9999999 !important;
}
