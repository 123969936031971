@import 'styles/function.scss';

.content {
  width: 100%;
}

.searchWrapper {
  width: pxToRem(375);
  padding: pxToRem(16) pxToRem(8);
}
