@import 'styles/function.scss';

.searchWrapper {
  width: 100%;
}

.searchResult {
  position: absolute;
  min-width: pxToRem(300);
  border-radius: 4px;
  box-shadow: 0 10px 10px gray;
  z-index: 10;
  overflow: hidden;
}

.searchResultWrapper {
  position: relative;
  overflow-y: auto;
  width: 100%;
  max-height: pxToRem(400);
  padding: pxToRem(8) 0;
  background-color: white;
}

.searchResultList {
  margin: 0;
  padding: 0;
}

.searchResultItem {
  cursor: pointer;
  padding: pxToRem(16);
  border-bottom: 1px solid rgba(black, .05);

  &:hover {
    background-color: #f7f7f7;
  }
}

.searchResultType {
  color: #999999;
}
