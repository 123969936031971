@import 'styles/function.scss';

.structureList {
  display: flex;
  flex-direction: column;

  .structureItem {
    display: flex;
    align-items: center;
    border-bottom: pxToRem(1) solid #cccccc;
    padding: pxToRem(8);

    &Expand {
      margin-right: pxToRem(10);
    }

    &Checkbox {
      margin-right: pxToRem(10);
    }

    &Name {
    }
  }
}
