@import 'styles/function.scss';

.wrapperDisabled {
  user-select: none;
  pointer-events: none;
  opacity: .7;
}

.row {
  margin-bottom: pxToRem(8);

  &:last-child {
    margin-bottom: 0;
  }
}

.rowButtonsList {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.rowButton {
  width: 25%;
  max-width: pxToRem(150);
  margin: 0 pxToRem(8) 0 0;

  &:last-child {
    margin: 0;
  }
}

.rowDatepickerList {
  display: flex;
  flex-wrap: nowrap;
}

.rowDatepicker {
  flex: 1;
  margin-left: pxToRem(8);
  width: pxToRem(184);

  &:first-child {
    margin-left: 0;
  }
}

.datePickerInput {
  input {
    width: auto;
  }
}

.popperDatePicker {
  z-index: 10 !important;
}
