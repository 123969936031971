@import 'styles/function.scss';

.table {
  &Header {
    background: #c4c4c4;
    font-size: pxToRem(18);
    height: pxToRem(32);
    color: #4d4d4e;

    &Date {
      width: pxToRem(80);
    }
    &Time {
      width: pxToRem(64);
    }
    &Text {
      width: pxToRem(368);
    }
    &Geozone {
      width: pxToRem(128);
    }
    &Coordinates {
      width: pxToRem(128);
    }
  }

  &BodyCell {
    background: rgba(233, 233, 233, 0.4);
    font-size: pxToRem(14);
    padding: pxToRem(4) pxToRem(6);

    &Coordinates {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & .tableBodyCellIcon,
      & .tableContent {
        flex-shrink: 0;
      }
    }
  }
}
