@import 'styles/function.scss';

.modalContainer {
  width: pxToRem(800);
  height: pxToRem(625);

  border-radius: pxToRem(5);

  background-color: #f1f1f1;
}

.modalHeader {
  user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: pxToRem(63);

  padding: 0 pxToRem(16);
  font-weight: 500;
  font-size: pxToRem(20);
  line-height: pxToRem(23);
}

.modalBody {
  height: pxToRem(500);
  overflow-y: auto;

  border-bottom: pxToRem(1) solid rgba(0, 0, 0, 0.12);
  border-top: pxToRem(1) solid rgba(0, 0, 0, 0.12);
}

.modalControls {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  height: pxToRem(60);
  padding: 0 pxToRem(8);
}

.modalContent {
  padding: pxToRem(16);
  font-size: pxToRem(14);
}
