.wrapper {

  #map {
    width: 100%;
    height: 100vh !important;

    .ol-zoom, .ol-zoomslider, .ol-scale-line-inner, .ol-mouse-position {
      display: none !important;
    }
  }
}
