@import 'styles/function.scss';

.headerContainer {
  display: flex;
  align-items: center;
}

.filtersContainer {
  display: flex;
  margin-left: auto;
  padding-right: 1rem;
}

.select {
  width: pxToRem(250);

  &:not(:first-child) {
    margin-left: 1rem;
  }
}

.table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;

  thead {
    border-bottom: pxToRem(1) solid #000;

    th {
      padding: pxToRem(12);

      font-size: pxToRem(14);
      font-weight: 500;

      cursor: pointer;
      user-select: none;

      &:last-child {
        padding: 0 pxToRem(8);
        text-align: right;
      }
    }
  }

  tbody {
    tr {
      border-bottom: pxToRem(1) solid #ccc;
      height: pxToRem(40);
    }

    td {
      padding: pxToRem(8) pxToRem(12);

      font-size: pxToRem(14);
      color: #4d4d4e;
    }
  }
}
