@import 'styles/function.scss';

.wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  font-size: pxToRem(20);
  font-weight: 500;
  
  border-top: 1px solid #ccc;
  height: pxToRem(64);

  padding: 0 pxToRem(16);
}