@import 'styles/function.scss';

.container {
    display: flex;
    flex-direction: column;
    height: calc(100% - 86px);

    padding: pxToRem(16);
}

.spinner {
    flex-grow: 1;
}

.tableWrap {
    width: pxToRem(780);
    height: pxToRem(400);

    overflow-y: scroll;
}

.table {
    width: 100%;
}

.tableHeadCell {
    background: #C4C4C4;
    font-weight: bold;
    font-size: pxToRem(18);
    color: #4D4D4E;
    padding: pxToRem(8);
    width: pxToRem(80);

    &:last-child {
        width: pxToRem(600)
    }
}

.tableRowCellAlignCenter {
    text-align: center;
}

.tableRowCell {
    background: #E9E9E9;
    color: #4D4D4E;
    font-size: pxToRem(14);
    padding: pxToRem(4);
    text-align: center;

    &:last-child {
        text-align: left;
    }
}

.tableRowCellDescription {
    word-break: break-word;
}

.tableRowCellIconWrapper {
    display: inline-flex;
    align-items: center;

    margin-right: pxToRem(8);

    &:last-child {
        margin-right: 0;
    }
}

.tableRowCellIconDescription {
    margin-right: pxToRem(8);
}

.tableRowCellIcon {
    display: inline-block;

    width: pxToRem(128);
    height: pxToRem(128);

    border: 1px solid #4D4D4E;

    object-fit: contain;
}

.pagination {
    padding-top: pxToRem(8);
}
