@import 'styles/function.scss';

.body {
  display: flex;
  flex: 1;

  overflow: hidden;
  z-index: 3;
}

.contentMap {
  position: relative;
  width: 100%;
}

.contentMapFull {
  position: relative;
  width: 100%;
}

.contentData {
  display: flex;
  flex-direction: column;

  min-width: pxToRem(520) !important;
  z-index: 4;
}

.contentDataOpen {
  animation: openContent 0.4s ease-out forwards;
}
.contentDataHide {
  animation: closeContent 0.4s ease-out forwards;
  display: none;
}

.contentDataFull {
  width: 100% !important;
}

.toggleButton {
  position: absolute;
  top: pxToRem(20);
  margin-left: pxToRem(11);
  width: pxToRem(25);
  height: pxToRem(25);
  background: #c4c4c4;
  z-index: 5;
  border-radius: 20px 20px;
  border: none;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}

.toggleButtonIconHide {
  transition: 0.5s;
  transform: rotate(0);
}

.toggleButtonIconShow {
  transition: 0.5s;
  transform: rotate(180deg);
}

.routesWrapper {
  flex: 1;
  overflow: auto;
}

@keyframes openContent {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes closeContent {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
