@import 'styles/function.scss';

.wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: pxToRem(24) 0;
}

.select {
  width: pxToRem(294);
  margin-bottom: 0;
}

.selectOptions {
  font-size: pxToRem(14);
}

.datesWrapper {
  display: flex;
  align-items: center;
}

.date {
  width: pxToRem(160);
  margin-right: pxToRem(16);
  &:last-child {
    margin-right: 0;
  }
}

.datePickerInput {
  input {
    width: pxToRem(140);
  }
}

.button {
  flex-shrink: 0;
  margin: 0;
}