@import 'styles/function.scss';

.wrapper {
  display: flex;
  flex-direction: column;

  height: calc(100vh - #{pxToRem(180)});
}

.footer {
  flex: 0 0 pxToRem(50);

  display: flex;
  align-items: center;
  justify-content: flex-end;

  border-top: 1px solid #D2D2D2;

  padding: 0 pxToRem(16);
}

.footerButton {
  max-width: pxToRem(145);

  margin: 0;
  margin-right: pxToRem(8);

  &:last-child {
    margin-right: 0;
  }
}

.popupHeader {
  font-size: pxToRem(20);
}

.popupContent {
  line-height: 128.9%;
  font-size: pxToRem(14);
  padding: pxToRem(12) 0;
}

.popupContentText {
  margin-bottom: pxToRem(16);

  &:last-child {
    margin-bottom: 0;
  }
}

.popupFooter {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 100%;
}
