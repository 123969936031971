@import 'styles/function.scss';

.modalContent {
    display: flex;

    padding: pxToRem(10) pxToRem(14);
}

.rolesBlock {
    margin-right: pxToRem(8);
}

.rolesBlockTitle {
    color: #4D4D4E;

    font-weight: bold;
    font-size: pxToRem(18);

    margin-bottom: pxToRem(10);
}

.rolesBlockRoles {
    overflow-y: scroll;
    background: #FFFFFF;

    border: pxToRem(1) solid #999999;
    border-radius: pxToRem(4);

    width: pxToRem(264);
    height: pxToRem(395);
}

.rolesBlockRoleItem {
    display: flex;
    align-items: center;

    padding: pxToRem(6) pxToRem(5);
}

.rolesBlockRoleItemLabel {
    color: #4D4D4E;

    font-weight: 500;
    font-size: pxToRem(14);

    margin-left: pxToRem(8);
}

.permissionsBlock {
    max-height: pxToRem(430);
    overflow-y: scroll;
}

.table {
    width: 100%;
}

.tableHeader {
    height: pxToRem(30);
    width: pxToRem(76);

    background: #E9E9E9;
    font-size: pxToRem(14);

    &:first-child {
        width: pxToRem(177);
        background: #C4C4C4;
        font-size: pxToRem(18);
    }
}

.tableBodyCell {
    background: rgba(233, 233, 233, 0.4);
    font-size: pxToRem(12);
    padding: pxToRem(4) pxToRem(6);

    &:first-child {
        background: #E9E9E9;
        font-weight: bold;
        color: #4D4D4E;
    }
}

.tableBodyCellCheckboxWrap {
    display: flex;
    align-items: center;
    justify-content: center;
}
