@import 'styles/function.scss';

.modalBody {
    height: pxToRem(500);
    border-bottom: pxToRem(1) solid rgba(0, 0, 0, 0.12);
    border-top: pxToRem(1) solid rgba(0, 0, 0, 0.12);
}

.modalWrapper {
    height: 100%;
}
