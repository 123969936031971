@import 'styles/function.scss';

.wrapper {
  position: relative;

  width: pxToRem(68);
  height: pxToRem(75);

  overflow: hidden;

  border: 1px solid #d2d2d2;
  border-radius: pxToRem(4);

  &Focused {
    border: 1px solid #0066FF;
  }

  &:hover:not(.wrapperFocused) {
    border: 1px solid #4D4D4E;
  }
}

.wrapperDisabled {
  user-select: none;
  pointer-events: none;
  color: rgba(0,0,0, .3);
}

.wrapperWarning {
  border: pxToRem(1) solid #F44336;
}

.inputWrapper {
  position: absolute;

  width: 100%;
  height: 100%;
}

.label {
  user-select: none;
  position: relative;

  top: pxToRem(3);
  left: pxToRem(5);

  color: #666666;
  font-size: pxToRem(10);

  &Focused {
    color: #0066FF;
  }
}

.input {
  display: none;
}

.img {
  cursor: pointer;
  display: block;

  width: 75%;
  height: 60%;

  object-fit: contain;

  margin: pxToRem(8);
}

.imgDisabled {
  user-select: none;
  pointer-events: none;
  opacity: .7;
  filter: grayscale(1);
}

.iconWarning {
  position: absolute;
  top: pxToRem(3);
  right: pxToRem(3);
  color: #F44336;
}

.clearBtn {
  cursor: pointer;
  position: absolute;

  top: pxToRem(5);
  right: pxToRem(5);

  width: pxToRem(16);
  height: pxToRem(16);

  outline: none;
  border: none;

  padding: 0;

  border-radius: 50%;
  overflow: hidden;
  box-shadow: pxToRem(3) pxToRem(3) pxToRem(5) #ccc;

  background-image: url('../../../assets/img/clear-button-icon.svg');
  background-repeat: no-repeat;
  background-size: contain;

  z-index: 1;

  transition: all .25s;

  &:hover {
    transform: scale(1.1);
  }
}
