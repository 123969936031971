@import 'styles/function.scss';

.wrapper {
  border: pxToRem(1) solid rgba(210, 210, 210, 0.5);
  box-sizing: border-box;
  border-radius: pxToRem(4);
  position: relative;

  padding: pxToRem(8) pxToRem(5);

  &Focused {
      border: 1px solid #0066FF;
  }

  &:hover:not(.wrapperFocused) {
      border: 1px solid #4D4D4E;
  }

  button {
    position: relative;
    left: 100%;
    transform: translateX(-100%);
    margin: 0;
  }
}

.wrapperWarning {
  border: pxToRem(1) solid #F44336;
}

.iconWarning {
  position: absolute;
  top: pxToRem(3);
  right: pxToRem(3);
  color: #F44336;
}

.label {
  user-select: none;
  font-size: pxToRem(10);
  line-height: pxToRem(13);
  letter-spacing: 0.01em;
  margin-left: pxToRem(3);
  margin-bottom: pxToRem(8);
  color: #666;
  padding: 0 pxToRem(11);

  &Focused {
      color: #0066FF;
  }
}

.table {

}

.tableContent {
  margin-bottom: pxToRem(8);
}

.tableHeaders,
.tableRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: pxToRem(4);
}

.tableRow {
  &:first-child {
    margin-top: pxToRem(8);
  }
}

.tableCell {
  flex: 1;
  width: pxToRem(78);
  margin-right: pxToRem(5);

  &:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &:last-child {
    margin-right: 0;
  }
}

.tableCellDescription {
  user-select: none;
  text-align: center;
  font-size: pxToRem(9);
  color: #666;
  margin-bottom: pxToRem(4);
}

.tableSelect {
  cursor: pointer;
  font-size: pxToRem(10);
  padding: 0;
  display: flex;
  align-items: center;

  svg {
    right: pxToRem(2);
  }

  input {
    width: pxToRem(66);
  }
}

.tableSelectOptions {
  max-width: pxToRem(200);
}

.selectedOptionStyle {
  max-width: calc(100% - 2.2rem);
  background-color: transparent;
}

.tableInput {
  font-size: pxToRem(14);
  padding: 0 pxToRem(4);
  width: 100%;

  div[class^="input_label"] {
    opacity: 0;
    visibility: hidden;
  }

  input {
    width: 100%;
    text-align: center;
  }
}

.tableRowDelete {
  flex-shrink: 0;
  cursor: pointer;
  transform: scale(1.4);

  &:hover {
    transform: scale(1.6);
  }
}

.tableRowDeleteFirst {
  position: relative;
  display: block;
  top: pxToRem(8);
}

.range {
  width: 100%;
  padding: 0 pxToRem(4) !important;

  input {
    margin-right: pxToRem(-4);
    min-width: pxToRem(10);
    text-align: center;
  }
}

.rangeInputsWrap {
  top: 50%;
  position: relative;
  transform: translateY(-50%);
}
