@import 'styles/function.scss';

.container {
  width: pxToRem(370);
  height: pxToRem(190);
  background: #f7f7f7;
  box-shadow: 0 0 pxToRem(22) rgba(0, 0, 0, 0.25);
  border-radius: pxToRem(8);

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 4;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: pxToRem(20);
  border-bottom: pxToRem(1) solid rgba(0, 0, 0, 0.12);
}

.content {
  padding: pxToRem(10);
}

.headerTitle {
  font-weight: 500;
  font-size: pxToRem(20);
  color: #4d4d4e;
}

.headerCloseIcon {
  & svg {
    cursor: pointer;

    &:hover {
      transform: scale(1.5);
      transition: 0.2s ease;
    }
  }
}

.body {
  padding: pxToRem(8) pxToRem(16);
  height: pxToRem(170);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: pxToRem(1) solid rgba(0, 0, 0, 0.12);
  padding: pxToRem(16);
}

.configHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: pxToRem(12) pxToRem(8);
}

.configItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border: pxToRem(1) solid #666666;
  border-radius: pxToRem(4);
  padding: pxToRem(12) pxToRem(8);
}
.configItemWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  z-index: 99999;
}

.configItemDragIcon {
  margin-left: pxToRem(10);
  cursor: pointer;
}

.configItemName {
  font-size: pxToRem(14);
  color: #4d4d4e;
  margin-left: pxToRem(10);
}

.configItemArrow {
  cursor: pointer;
  margin: pxToRem(10);
}


.buttonBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: pxToRem(16) 0 pxToRem(8) 0;
  padding: 0 pxToRem(8);
}

.searchWrapper {
  min-width: pxToRem(265);
  width: 100%;
}

.geozoneCreateButton {
  margin-left: pxToRem(8);

  button {
    width: pxToRem(120);
  }
}

.groupWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-weight: 500;
  font-size: pxToRem(14);
  line-height: pxToRem(16);
  color: #4d4d4e;
  height: pxToRem(40);
  border-bottom: pxToRem(1) solid #ccc;
}

.geozoneWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-weight: 500;
  font-size: pxToRem(14);
  line-height: pxToRem(16);
  color: #4d4d4e;
  height: pxToRem(40);
  border-bottom: pxToRem(1) solid #ccc;
}

.geoZonesListHead {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-weight: 500;
  font-size: pxToRem(14);
  line-height: pxToRem(16);
  height: pxToRem(40);
  border-bottom: pxToRem(1) solid;

  border-color: #000;
  color: #000;
}

.geoZonesListInfo {
  padding-left: pxToRem(10);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.geoZonesListInfoTitle {
  margin-left: pxToRem(10);
}

.geoZonesListInfoExpandButton {
  flex-shrink: 0;
  margin-right: pxToRem(20);
}

.geoZonesIcons {
  display: flex;
}

.geoZonesIconsIcon {
  width: pxToRem(28);
  height: pxToRem(20);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 pxToRem(2);
  position: relative;
}

.geoZonesIconsIconPointer {
  cursor: pointer;
}

.geoZonesIconsIconPointerDisabled {
  pointer-events: none;
  opacity: .5;
}

.geoZonesIconsCount {
  font-weight: 500;
  font-size: pxToRem(14);
  color: #000000;
}
