@import 'styles/function.scss';
@import 'styles/mixins.scss';

.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: pxToRem(35) 1fr pxToRem(48);

  height: calc(100vh - 142px);
}

.header {
  border-bottom: pxToRem(1) solid #d2d2d2;

  padding: pxToRem(8);
}

.title {
  margin: 0;
}

.historyList {
  height: calc(100vh - 225px);
  overflow-y: auto;

  color: #4d4d4e;
}

.historyItem {
  display: flex;
  border-bottom: pxToRem(1) solid #d2d2d2;
}

.historyItemSpinner {
  justify-content: center;
}

.historyNumber {
  flex-shrink: 0;

  padding-top: pxToRem(10);
  margin: 0 pxToRem(8);
}

.historyContent {
  flex-grow: 1;
}

.historyContentHeader {
  display: flex;
  justify-content: space-between;

  font-size: pxToRem(14);

  padding-top: pxToRem(8);
  margin-bottom: pxToRem(8);

  &Left,
  &Right {
    display: flex;
    align-items: center;
  }
}

.historyContentFooter {
  font-size: pxToRem(12);
}

.historyContentFooterRow {
  max-width: 100%;
  margin-bottom: pxToRem(4);
}

.historyName {
  font-weight: bold;

  &Done {
    cursor: pointer;
    text-decoration: underline;
    color: #0066ff;
  }
}

.historyStatus {
  user-select: none;
  white-space: nowrap;
  text-align: center;

  min-width: pxToRem(60);

  font-size: pxToRem(12);
  border: 1px solid black;
  border-radius: pxToRem(16);

  margin-right: pxToRem(24);
  padding: 0 pxToRem(4);

  &Done {
    color: #00bf78;
    border-color: #00bf78;
  }

  &InProgress {
    color: #ff8f00;
    border-color: #ff8f00;
  }

  &Error {
    color: #ff1e00;
    border-color: #ff1e00;
  }
}

.historyDelete {
  cursor: pointer;
  margin-right: pxToRem(16);
}

.historyObjects {
  display: inline-block;
  vertical-align: bottom;

  white-space: nowrap;
  overflow: hidden;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding: 0 pxToRem(16);
}

.footerButton {
  margin: 0;
}

.customTooltip {
  @include inc-weight-selector() {
    background: #ffffff;
    border: 0.5px solid #0066ff;
    border-radius: 5px;
    width: fit-content;
    height: fit-content;
    padding: pxToRem(5) pxToRem(5);
    color: #000000;
    font-size: pxToRem(11);
    line-height: pxToRem(11);
    opacity: 1;
    margin-top: pxToRem(20);
    margin-left: 0;

    z-index: 9999999;
  }
}
