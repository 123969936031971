@import 'styles/function.scss';

.wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;

  padding: pxToRem(10);
}

.row {
  flex: 1;
  min-height: pxToRem(140);

  margin-bottom: pxToRem(4);
  margin-right: pxToRem(24);

  &:last-child {
    margin-bottom: 0;
    margin-right: 0;
  }
}

.rowTextarea {
  textarea {
    min-height: pxToRem(140);
    width: calc(100% - 30px);
  }
}
