@import 'styles/function.scss';

.contentControls {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: pxToRem(16) 0 pxToRem(8) 0;
  padding: 0 pxToRem(8);
}

.contentSearch {
  width: 100%;
}

.dropButton {
  margin-left: pxToRem(8);

  button {
    width: pxToRem(120);
  }
}
