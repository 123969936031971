@import 'styles/function.scss';

.trackerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-weight: 500;
  font-size: pxToRem(14);
  line-height: pxToRem(16);
  color: #4d4d4e;
  height: pxToRem(40);
  border-bottom: pxToRem(1) solid #ccc;
}
.trackerWrapper:not(:first-child):hover {
  background-color: rgb(233, 233, 233);
}
.trackerWrapperSelected {
  background: #e6f7ff;
}
.trackerWrapperSelected:hover {
  background: #dcf4ff;
}

.buttonBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: pxToRem(16) 0 pxToRem(8) 0;
  padding: 0 pxToRem(8);
}

.searchWrapper {
  min-width: pxToRem(265);
  width: 100%;
}

.trackerCreateButton {
  flex-shrink: 0;
  width: pxToRem(120);
  margin-right: 0;
}

.trackerInfo {
  padding-left: pxToRem(8);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.trackerButtons {
  display: flex;
}
.trackerButtonsIcon {
  cursor: pointer;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  width: pxToRem(28);
  height: pxToRem(20);

  margin: 0 pxToRem(2);

  &0 path {
    fill: #c8c8c8;
  }
  &1 path {
    fill: #f44336;
  }
  &2 path {
    fill: #ff8f00;
  }
  &3 path {
    fill: #efe100;
  }
  &4 path {
    fill: #00bf78;
  }

  &Text {
    color: #ffffff;
    font-size: pxToRem(8);
    position: absolute;
    left: pxToRem(3);
  }

  &Disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.trackerButtonsItem svg,
.trackerButtonsItem span {
  cursor: pointer;
}

.iconConnectionState {
  &true circle {
    fill: #00bf78;
  }
  &false circle {
    fill: #999999;
  }
}

.deleteIcon {
  cursor: pointer;
}

.id {
  margin: 0 pxToRem(11);
  width: pxToRem(30);
  text-align: center;
  overflow: hidden;
}
.idHead {
  cursor: pointer;
  margin-left: pxToRem(10);
  width: pxToRem(40);
}

.name {
  padding-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: pxToRem(100);
}
.nameHead {
  cursor: pointer;
  margin-left: pxToRem(10);
}

.trackerListHeader {
  border-color: #000;
  color: #000;
}

.trackerListHeader .name {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modalBody {
  position: relative;
  height: pxToRem(500);
  border-bottom: pxToRem(1) solid rgba(0, 0, 0, 0.12);
  border-top: pxToRem(1) solid rgba(0, 0, 0, 0.12);
}

.modalSpinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modalBodyDelete {
  border-bottom: pxToRem(1) solid rgba(0, 0, 0, 0.12);
  border-top: pxToRem(1) solid rgba(0, 0, 0, 0.12);
}

.modalBodyDeleteText {
  font-size: pxToRem(14);
  padding: 0 pxToRem(24);
}
.modalBodyDeleteLabel {
  font-weight: 500;
  padding: 0 pxToRem(30);
}

.iconGpsState {
  &Off g path {
    fill: #828282;
  }
  &On g path {
    fill: #2f80ed;
  }
  &Active path {
    fill: #2f80ed;
  }
}

.customTooltip {
  background: #ffffff !important;
  border: 0.5px solid #0066ff !important;
  border-radius: 5px !important;
  width: fit-content !important;
  height: fit-content !important;
  padding: pxToRem(5) pxToRem(5) !important;
  color: #000000 !important;
  font-size: pxToRem(11) !important;
  line-height: pxToRem(11) !important;
  opacity: 1 !important;
  margin-top: pxToRem(20) !important;
  margin-left: 0 !important;

  z-index: 9999999 !important;
}
