@import 'styles/function.scss';

.wrapper {
  border: pxToRem(1) solid rgba(210, 210, 210, 0.5);
  box-sizing: border-box;
  border-radius: pxToRem(4);
  position: relative;

  height: pxToRem(48);

  padding: pxToRem(8) pxToRem(16);

  &Focused {
      border: 1px solid #0066FF;
  }

  &:hover:not(.wrapperFocused) {
      border: 1px solid #4D4D4E;
  }
}

.wrapperWarning {
  border: pxToRem(1) solid #F44336;
}

.iconWarning {
  position: absolute;
  top: pxToRem(3);
  right: pxToRem(3);
  color: #F44336;
}

.iconBase {
  position: absolute;
  top: 12px;
  right: 12px;
  color: #666;
  width: pxToRem(22);
  height: pxToRem(22);
  cursor: pointer;

  &Focused path{
      fill: #0066FF;
  }
}

.label {
  font-size: pxToRem(10);
  line-height: pxToRem(13);
  letter-spacing: 0.01em;
  margin-left: pxToRem(3);
  color: #666;

  &Focused {
      color: #0066FF;
  }
}

.inputsWrapper {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}

.input {
  font-size: pxToRem(14);
  line-height: pxToRem(18);
  letter-spacing: 0.01em;

  background-color: transparent;
  
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  border: 0;
  outline: 0;
  margin-right: pxToRem(-20);

}

.input::placeholder {
  color: rgba(0, 0, 0, 0.2);
}

.separator {
  padding: 0 pxToRem(4);
}