@import 'styles/function.scss';

.container {
    width: pxToRem(800);
    height: pxToRem(600);
    background: #F7F7F7;
    box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.25);
    border-radius: 8px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    z-index: 9999;

    user-select: none;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.header {
    width: pxToRem(752);
    height: pxToRem(41);
    margin-top: pxToRem(10);
    padding: 0 pxToRem(24) pxToRem(7) pxToRem(24);
    display: flex;
    border-bottom: pxToRem(2) solid rgba(0, 0, 0, 0.12);

    display: flex;
    justify-content: space-between;
    align-items: center;

    &Text {
        color: #4D4D4E;
        font-weight: 500;
        font-size: pxToRem(20);
        line-height: pxToRem(23);
    }
    &CloseIcon {
        width: pxToRem(24);
        height: pxToRem(24);
        cursor: pointer;

        & path {
            fill: #999999;
        }

        &:hover {
            transform: scale(1.3);
            transition: 0.2s ease;
        }
    }
}

.content {
    flex: 1;
}

.filterBlock {
    display: flex;
    margin: pxToRem(16) pxToRem(24);

    &Search {
        width: pxToRem(340);
    }

    .checkboxWrap {
        display: flex;
        align-items: center;

        margin-left: pxToRem(70);

        &Text {
            margin-left: pxToRem(10);
            font-size: pxToRem(14);
            line-height: 128%;
            color: #4D4D4E;
        }
    }

}

.modalBody {
    padding: pxToRem(10) pxToRem(24);
}

.objectsList {
    display: flex;
    justify-content: space-between;

    &Active {}

    &Disabled {}

    &Header {
        color: #4D4D4E;
        font-size: pxToRem(18);
        text-align: center;
        margin-bottom: pxToRem(8);
    }

    &ItemsWrap {
        background: #FFFFFF;
        border: pxToRem(1) solid #999999;
        border-radius: pxToRem(4);
        padding: 0;

        width: pxToRem(340);
        height: pxToRem(304);

        overflow-y: scroll;

        margin-bottom: pxToRem(8);

        &::-webkit-scrollbar-track {
            background: #FFFFFF;
            border-radius: pxToRem(4);
        }

        &::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0.11);
        }
    }

    &Item {
        color: #4D4D4E;
        border-bottom: pxToRem(1) solid rgba(0, 0, 0, 0.05);
        padding: pxToRem(8) pxToRem(16);
        cursor: pointer;
        z-index: 99999;

        &:hover {
            background-color: #F7F7F7;
        }

        &Selected {
            background-color: #EFEFEF;
        }

    }

    .selectAllButton {
        align-self: center;
        margin: auto;
    }
}

.moveButtons {
    display: flex;
    flex-direction: column;
    align-self: center;

    &Btn {
        border-radius: 20px 20px;
        border: pxToRem(1) solid #D2D2D2;
        cursor: pointer;

        width: pxToRem(32);
        height: pxToRem(32);

        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: pxToRem(8);

        &ToRight {
            transform: rotate(180deg);
        }
        &ToLeft {
            transform: rotate(0);
        }
    }
}

.modalFooter {
    height: pxToRem(48);
    border-top: pxToRem(2) solid rgba(0, 0, 0, 0.12);
    display: flex;
    justify-content: flex-end;
    padding-right: pxToRem(24);
    padding-top: pxToRem(15);

    &ButtonsWrap {
        display: flex;
    }
}
