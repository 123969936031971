@import 'styles/function.scss';

.content {
  display: flex;
}

.column {
  flex-basis: 50%;
  margin-right: pxToRem(18);

  &:last-child {
    margin-right: 0;
  }
}

.row {
  margin-bottom: pxToRem(16);

  &:last-child {
    margin-bottom: 0;
  }
}

.rowFullWidth{
  textarea {
    width: calc(100% - 28px);
  }
  input {
    width: pxToRem(300);
  }
}

.rowWithTooltip {
  display: flex;
  justify-content: space-between;
}

.rowInput,
.multipleSelect {
  width: calc(100% - 28px);

  margin-right: pxToRem(18);
  margin-bottom: 0;

  ul {
    max-height: pxToRem(250);
  }
}

.tooltip {
  position: relative;
  top: pxToRem(12);
}

.areaStyle {
  min-height: pxToRem(70);
}
