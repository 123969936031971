@import 'styles/function.scss';

.history {
  font-size: pxToRem(14);
  color: #4d4d4d;
}

.historyHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  user-select: none;

  border-bottom: pxToRem(1) solid #d2d2d2;

  padding: pxToRem(8);

  &Left, &Right {
    display: flex;
    align-items: center;
  }

  &Right {
    margin-right: pxToRem(10);
  }

  &Checkbox, &Number {
    margin-right: pxToRem(8);
  }

  &Number {
    width: pxToRem(20);
  }

  &Status {
    max-width: pxToRem(85);

    margin-right: pxToRem(55);
  }
}

.historyList {
  height: calc(100vh - 445px);
  overflow-y: auto;
}

.historyItem {
  border-bottom: pxToRem(1) solid #d2d2d2;

  padding: pxToRem(8);
  margin-bottom: pxToRem(8);

  &:last-child {
    margin-bottom: 0;
  }

  &Header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: pxToRem(8);

    &Left, &Right {
      display: flex;
      align-items: center;
    }

    &Left, &Map {
      cursor: pointer;
    }

    &Checkbox, &Number {
      margin-right: pxToRem(8);
    }

    &Number {
      min-width: pxToRem(20);
      font-weight: bold;
      text-align: center;
    }

    &Name {
      font-weight: bold;
      color: #0066FF;
      text-decoration: underline;

      white-space: nowrap;

      max-width: pxToRem(260);
    }

    &Status {
      user-select: none;
      white-space: nowrap;
      text-align: center;

      min-width: pxToRem(88);

      font-size: pxToRem(12);
      border: 1px solid black;
      border-radius: pxToRem(16);

      margin-right: pxToRem(30);

      &Read {
        color: #00BF78;
        border-color: #00BF78;
      }

      &NoRead {
        color: #FF8F00;
        border-color: #FF8F00;
      }
    }

    &Map {
      width: pxToRem(40);
      text-align: center;
    }
  }

  &Data {
    font-size: pxToRem(12);
    line-height: 128%;

    margin-left: pxToRem(55);

    &Bold {
      font-weight: bold;
    }
  }
}
