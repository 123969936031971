@import 'styles/function.scss';

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.title {
  margin-bottom: pxToRem(6);
  font-size: pxToRem(13);
  color: #ff8f00;
}

.videoContainer {
  position: relative;
  height: 100%;

  margin: 0;
  padding: 0;

  overflow: hidden;
  background: #c4c4c4;
  border-radius: pxToRem(4);
}

.playButton {
  position: absolute;
  cursor: pointer;

  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  &:active {
    transform: translate(-50%, -50%) scale(0.9);
  }
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  height: 100%;

  object-fit: cover;
}
