@import 'styles/function.scss';

.dateFilter {
    display: flex;
    align-items: center;
    margin-right: pxToRem(4);
    margin-left: -(pxToRem(12));
}
.table {
    width: pxToRem(790);
    margin: 0 pxToRem(10) pxToRem(10) pxToRem(10);
    height: pxToRem(350);

    overflow-y: scroll;
}

.tableWrap {
    width: 100%;
}

.tableHead {
    background-color: #C4C4C4;
}

.tableHeadCell {
    height: pxToRem(40);
    font-weight: normal;
    font-size: pxToRem(14);
    color: #000000;
}

.tableHeadCellFirst {
    width: pxToRem(82);
}

.tableHeadCellSecond {
    width: pxToRem(62);
}

.tableHeadCellThird {
    width: pxToRem(76);
}

.tableHeadCellFourth {
    width: pxToRem(210);
}
.tableHeadCellFifth {
    width: pxToRem(199);
}
.tableHeadCellSixth {
    width: pxToRem(142);
}

.tableRow {
    background-color: #E9E9E9;
}

.tableRowCell {
    height: pxToRem(40);
    font-weight: normal;
    font-size: pxToRem(14);
    color: #000000;
    padding: 0 pxToRem(5);
}

.tableRowCellAlignCenter {
    text-align: center;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: pxToRem(63);

    padding: 0 pxToRem(24);
    font-weight: 500;
    font-size: pxToRem(20);
    line-height: pxToRem(23);
}

.modalBody {
    height: pxToRem(500);
    border-bottom: pxToRem(1) solid rgba(0, 0, 0, 0.12);
    border-top: pxToRem(1) solid rgba(0, 0, 0, 0.12);
}

.modalControls {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    height: pxToRem(56);
    padding: 0 pxToRem(24);
}

.inputWrap {
    margin: pxToRem(24);
    width: pxToRem(310);
}
