@import 'styles/function.scss';

.headerContainer {
  display: flex;
  padding: pxToRem(16);
}

.searchContainer {
  width: pxToRem(300);
}

.createButton {
  min-width: pxToRem(100);
  margin: 0 pxToRem(16);
}
