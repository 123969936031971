@import 'styles/function.scss';

.sortableItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: pxToRem(23);
    z-index: 99999;
}

.sortableList {
    display: flex;
    flex-direction: column;
    margin-top: pxToRem(40);
    margin-left: pxToRem(184);
    margin-right: pxToRem(99);

    height: pxToRem(350);
    overflow-y: scroll;
}

