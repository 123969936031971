@import 'styles/function.scss';

.popupHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &Name {
    display: flex;
    align-items: center;
  }

  &Icon {
    width: pxToRem(26);
    height: pxToRem(26);
    margin-right: pxToRem(8);
  }

  &Title {
    display: flex;
    flex-direction: column;
    color: #ff8f00;

    h4 {
      font-weight: normal;
      margin: 0;
    }
  }

  &Close {
    cursor: pointer;
    width: pxToRem(24);
    height: pxToRem(24);
    margin-right: pxToRem(16);

    svg {
      width: 100%;
      height: 100%;
      opacity: 0.7;
    }

    &:hover {
      transform: scale(1.3);
      transition: 0.2s ease;
    }
  }
}

.popupInfo {
  display: flex;
  flex-wrap: nowrap;

  border-bottom: 1px solid #ccc;
  padding: pxToRem(11) pxToRem(8);

  &Icon {
    flex-shrink: 0;
    width: pxToRem(94);
    height: pxToRem(128);
    border-radius: pxToRem(8);
    border: 1px solid #ccc;
    overflow: hidden;
    margin-right: pxToRem(15);

    &Image {
      height: pxToRem(112);
    }

    &Photo {
      width: 100%;
      height: 100%;
      object-fit: none;
    }

    &Number {
      height: pxToRem(16);
      background-color: #0066ff;
      color: white;
      text-align: center;
      bottom: 0;
    }
  }

  &Content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &Data {
      margin-bottom: pxToRem(5);

      &:last-child {
        margin-bottom: 0;
      }

      div {
        display: flex;
        align-items: center;
        margin-bottom: pxToRem(5);
        border-left: 1px solid #4d4d4e;
        padding-left: pxToRem(4);

        &:last-child {
          margin-bottom: 0;
        }
      }

      &Name,
      &Position {
        color: black;
      }
      &Number {
        color: #0066ff;
      }
    }
  }
}

.NotificationData {
  div {
    color: #4d4d4e;
  }
}

.popupDescription {
  padding: pxToRem(11) pxToRem(8);

  &Title {
    font-size: pxToRem(14);
    font-weight: normal;
    color: #ff8f00;
    margin: 0;
    margin-bottom: pxToRem(6);
  }

  &Text {
    position: relative;
    left: pxToRem(4);
    line-height: 138%;
    border-left: 1px solid #4d4d4e;
    padding-left: pxToRem(4);
  }
}

.popupBtns {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding: pxToRem(15) 0;
  border-top: 1px solid #ccc;

  &MaxWidth {
    max-width: pxToRem(91);
  }
}

.customContentStyles {
  padding: 0;
}
