@import 'styles/function.scss';

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: pxToRem(63);

    padding: 0 pxToRem(24);
    font-weight: 500;
    font-size: pxToRem(20);
    line-height: pxToRem(23);
}
.closeModalIcon {
    cursor: pointer;
}
.closeModalIcon:hover {
    transform: scale(1.5);
}