@import 'styles/function.scss';

.content {
  display: flex;
  flex-direction: column;
}

.row {
  margin-bottom: pxToRem(16);

  &:last-child {
    margin-bottom: 0;
  }
}

.rowDataList {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rowSearch {
  width: 45%;
}

.dataList {
  display: flex;
  justify-content: space-between;
}

.dataListHeader {
  user-select: none;
  color: #4d4d4e;
  font-size: pxToRem(18);
  text-align: center;
}

.dataListItems {
  list-style: none;
  background: #ffffff;
  border: pxToRem(1) solid #999999;
  border-radius: pxToRem(4);
  padding: 0;

  width: pxToRem(340);
  height: pxToRem(260);

  margin: pxToRem(8) 0;

  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: pxToRem(4);
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.11);
  }

  &Error {
    border-color: red;
  }
}

.dataListItem {
  font-size: pxToRem(14);
}

.dataListItemRow {
  cursor: pointer;
  user-select: none;

  display: flex;
  align-items: center;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  border-bottom: 1px solid #ccc;
  padding: pxToRem(8) pxToRem(4);

  z-index: 9999;

  &:hover {
    background-color: rgba(#ccc, .3);
  }
}

.dataListItemRowSpaceBetween {
  justify-content: space-between;
  flex-basis: 100%;
}

.dataListItemRowIsChoose {
  background-color: rgba(#ccc, .3);
}

.dataListItemRowProperties {
  user-select: none;
  padding-left: pxToRem(40);
}
.dataListItemRowProperty {
  max-width: pxToRem(330);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dataListItemExpand {
  margin-right: pxToRem(8);
}

.dataListItemName {
  font-weight: bold;
}

.dataListItemText {
  display: flex;
  align-items: center;
}

.dataListItemChosenName {
  max-width: pxToRem(220);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sortableListItem {
  display: flex;
  align-items: center;

  font-size: pxToRem(14);
  border-bottom: 1px solid #ccc;

  z-index: 9999;

  &:hover {
    background-color: rgba(#ccc, .3);
  }
}
.sortableListItemIsChoose {
  background-color: rgba(#ccc, .3);
}
.sortableListItemRow {
  cursor: pointer;
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  padding: pxToRem(8) 0;
}
.sortableListItemChosenName {
  max-width: pxToRem(250);
  overflow: hidden;
  text-overflow: ellipsis;
}
.sortableListItemManualSorting {
  cursor: n-resize;
  flex-shrink: 0;
  margin: 0 pxToRem(8);
}

.sortableListItemAutoSorting {
  position: relative;
  flex-shrink: 0;
  margin-right: pxToRem(30);
}
.sortableListItemAutoSortingAscending {
  path {
    fill: #00BF78;
  }
}
.sortableListItemAutoSortingDescending {
  transform: rotate(180deg);
  path {
    fill: #00BF78;
  }
}
.sortableListItemChosenInput {
  background-color: transparent;
  border: 0;
  outline: 0;
  width: pxToRem(220);
}

.sortableListItemFullWidth {
  width: 100%;
}

.dataListButtons {
  display: flex;
  align-items: center;
}

.dataListButton {
  position: relative;
  right: pxToRem(70);

  max-width: pxToRem(130);

  padding: pxToRem(8) pxToRem(10);
  margin-left: auto;
}

.tooltip {
  position: relative;
  right: pxToRem(10);
}

.moveButtons {
  display: flex;
  flex-direction: column;
  align-self: center;
}

.moveButtonsBtn {
  user-select: none;
  cursor: pointer;
  border-radius: pxToRem(20) pxToRem(20);
  border: pxToRem(1) solid #d2d2d2;

  width: pxToRem(32);
  height: pxToRem(32);

  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: pxToRem(8);

  &:hover {
    border-color: #666;
  }
  &:active {
    box-shadow: 0px pxToRem(1) pxToRem(24) rgba(0, 0, 0, 0.3);
  }
}

.moveButtonsBtnToRight {
  transform: rotate(180deg);
}

.moveButtonsBtnToLeft {
  transform: rotate(0);
}
