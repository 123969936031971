@import 'styles/function.scss';

.container {
  position: relative;
}

%triangle {
  display: inline-block;
  position: absolute;
  right: pxToRem(-6);
  bottom: 0;

  border: 3px solid transparent;
  border-top-color: black;
  border-bottom-width: 0;
  border-left-width: 2px;
  border-right-width: 2px;

  content: '';
}

.ascending {
  &:after {
    @extend %triangle;
    transform: rotate(180deg);
  }
}

.descending {
  &:after {
    @extend %triangle;
  }
}
