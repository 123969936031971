@import 'styles/function.scss';

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // align-items: center;
    height: pxToRem(380);
    padding-top: pxToRem(20);
    padding-left: pxToRem(220);
}

.item {
    display: flex;
    align-items: center;
    width: pxToRem(374);

    &Warning {
        color: #F44336;
        font-size: pxToRem(14);
        margin-left: pxToRem(15);
    }
}
