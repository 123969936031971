@import 'styles/function.scss';

.container {
  display: flex !important;
  flex-direction: column;

  border-radius: pxToRem(5);
  border: 1px solid rgba(#000, 0.3);

  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);

  z-index: 100;
}

.header {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;

  padding: pxToRem(10);
  border-bottom: 1px solid #ccc;
  user-select: none;

  &__title {
    cursor: all-scroll;
    overflow-wrap: anywhere;
  }

  &__buttons {
    display: flex;
    flex-wrap: nowrap;
    flex-shrink: 0;
  }

  &__button {
    width: pxToRem(24);
    height: pxToRem(24);

    margin-right: pxToRem(8);
    transition: transform 0.25s;

    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      transform: scale(1.2);
    }
  }
}

.content {
  padding: pxToRem(10);
}

.footer {
  flex: 1;
  padding: pxToRem(10);
  overflow: auto;
}

.spaceForResizeHandler {
  flex-shrink: 0;
  height: pxToRem(10);
}
