@import 'styles/function.scss';

.container {
  border: pxToRem(1) solid rgba(210, 210, 210, 0.5);
  box-sizing: border-box;
  border-radius: pxToRem(4);
  width: 100%;

  &:hover:not(.containerFocused) {
    border: 1px solid #4d4d4e;
  }
}

.containerWarning {
  border: pxToRem(1) solid #f44336;
}

.containerFocused {
  border: 1px solid #0066ff;
}

.containerDisabled {
  user-select: none;
  pointer-events: none;
}

.select {
  width: 100%;
}

.selectWrapper {
  position: relative;

  padding: pxToRem(8) pxToRem(16);

  z-index: 1;
}

.selectWrapperDisabled {
  user-select: none;
  pointer-events: none;
  color: rgba(#000, .3);
}

.selectIconWarning {
  position: absolute;

  top: pxToRem(3);
  right: pxToRem(3);

  color: #f44336;
}

.selectIconBase {
  position: absolute;

  top: 12px;
  right: 12px;

  cursor: pointer;
}

.selectIconBaseFocused {
  & path{
    fill: #0066ff;
  }
}

.selectIconBaseDisabled {
  user-select: none;
  pointer-events: none;

  & path{
    fill: rgba(#000, .3);
  }
}

.selectButtonBase {
  cursor: pointer;
  position: absolute;

  top: pxToRem(8);
  right: pxToRem(42);

  border: pxToRem(1) solid rgba(210, 210, 210, 0.5);
  border-radius: pxToRem(4);

  svg {
    transform: translateY(2px);

    & path{
      fill: #666;
    }
  }

  &:hover {
    border-color: #4d4d4e;
  }

  &:focus {
    border-color: #0066ff;

    svg {

      & path{
        fill: #0066ff;
      }
    }
  }

  &:active {
    box-shadow: rgba(210, 210, 210, 1) 1px 1px 20px 0px inset;
  }
}

.selectButtonBaseDisabled {
  user-select: none;
  pointer-events: none;

  svg {
    & path{
      fill: rgba(#000, .3);
    }
  }
}

.selectLabel {
  user-select: none;
  pointer-events: none;
  font-size: pxToRem(10);
  line-height: pxToRem(13);
  letter-spacing: 0.01em;
  margin-left: pxToRem(3);
  color: #666;
}

.selectLabelFocused {
  color: #0066ff;
}

.selectLabelDisabled {
  user-select: none;
  pointer-events: none;
  color: rgba(#000, .3);
}

.selectInput {
  font-size: pxToRem(14);
  line-height: pxToRem(18);
  letter-spacing: 0.01em;
  width: 18em;

  background-color: transparent;

  border: 0;
  outline: 0;

  &::placeholder {
    user-select: none;
    color: rgba(0, 0, 0, 0.2);
  }
}

.selectOptionsGroupList {
  font-size: pxToRem(14);

  max-height: pxToRem(180);
  width: pxToRem(354);

  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;

  overflow-y: scroll;

  &::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: pxToRem(4);
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.11);
  }
}

.selectOptionName {
  user-select: none;

  color: rgba(0, 0, 0, 0.5);

  padding: pxToRem(12) pxToRem(16) pxToRem(6);
}

.selectOptionsListItem {
  word-break: break-all;
  cursor: pointer;
  border-bottom: pxToRem(1) solid rgba(0, 0, 0, 0.05);

  padding: pxToRem(12) pxToRem(16);

  &:hover {
    background: #f7f7f7;
  }
}

.selectOptionsListItemSub {
  padding-left: pxToRem(32);
}

.values {
  font-size: pxToRem(14);
}

.valuesGroupList {
  max-height: pxToRem(140);
  overflow-y: auto;
}

.valuesGroupListItem {
  padding-left: pxToRem(8);
}

.valuesGroupListName {
  user-select: none;

  color: rgba(0, 0, 0, 0.5);

  padding: pxToRem(6);
}

.valuesListItem {
  display: flex;

  word-break: break-all;
  font-size: pxToRem(14);

  background-color: rgba(black, .04);
  border-radius: pxToRem(50);

  width: fit-content;

  padding: pxToRem(8) pxToRem(10);
  margin: pxToRem(8);
}

.valuesListItemDeleted {
  background-color: #f44336;
  color: white;
}

.valuesListItemLabel {
  margin-right: pxToRem(8);
}

.valuesListItemDisabled {
  pointer-events: none;
  opacity: .7;
}

.valuesListItemInline {
  display: inline-flex;
}

.closeModalIcon {
  cursor: pointer;
  width: pxToRem(16);
  height: pxToRem(16);

  &:hover {
    transform: scale(1.5);
  }
}

.closeModalIconDeleted {
  background-color: #f44336;
  fill: rgba(#fff, .7);
}

.closeModalIconDisabled {
  &:hover {
    transform: none;
  }
}
