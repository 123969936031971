@import 'styles/function.scss';

.groupCell {
  display: flex;
  align-items: center;

  .expandButton {
    flex: 0 0 auto;
  }

  .groupLabel {
    margin-left: pxToRem(10);
  }
}

.itemIconsCell {
  padding: 0 0.5rem !important;
}

.itemIconsContainer {
  display: flex;
  justify-content: right;
  align-items: center;
}

.editIcon {
  cursor: pointer;
  margin-left: 1rem;
}
