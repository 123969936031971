@import 'styles/function.scss';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  user-select: none;

  font-weight: 500;
  font-size: pxToRem(14);
  line-height: pxToRem(16);
  height: pxToRem(40);
  border-bottom: pxToRem(1) solid black;

  padding-left: pxToRem(37);
}

.periods {
  margin: pxToRem(8) pxToRem(16);
}

.select {
  border-bottom: pxToRem(1) solid black;
  width: calc(100% - 32px);

  padding: 0 pxToRem(16);
  padding-bottom: pxToRem(8);
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;

  bottom: 0;

  width: 100%;
  height: pxToRem(64);
}

.button {
  &Create {
    max-width: pxToRem(144);
    margin-right: pxToRem(16);
  }
}
