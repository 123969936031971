@import 'styles/function.scss';

.popupContainer {
  width: pxToRem(400);
  position: fixed;
  background-color: #ffffff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: pxToRem(15);
  border-collapse: collapse;
  border: 1px solid #cccccc;
  font-size: pxToRem(14);
}

.popupSpinner {
  position: absolute;

  height: 100%;
  width: 100%;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  margin-top: pxToRem(-10);
}

.popupHeader {
  display: flex;
  align-items: center;

  font-size: pxToRem(14);
}

.popupHeaderIcon {
  width: pxToRem(24);
  height: pxToRem(20);
  filter: saturate(0);

  margin-right: pxToRem(8);
}

.popupHeaderOpenModal {
  cursor: pointer;

  width: pxToRem(24);
  height: pxToRem(24);

  transition: transform 0.25s;

  &:hover {
    transform: scale(1.2);
  }
}

.popupMain {
  display: flex;
  position: relative;

  font-size: pxToRem(14);
  color: #000000;
  word-break: break-word;

  .popupButtons {
    position: absolute;
    width: pxToRem(50);
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & svg {
      cursor: pointer;

      &:hover {
        transform: scale(1.5);
        transition: 0.2s ease;
      }
    }
  }
}

.popupSide {
  display: flex;
  flex-direction: column;
  margin-right: pxToRem(15);
}

.popupSideButton {
  width: pxToRem(94);
  height: pxToRem(24);
  border: pxToRem(1) solid #d2d2d2;
  border-radius: pxToRem(40);

  display: flex;
  align-items: center;
  justify-content: space-around;

  cursor: pointer;
  text-decoration: none;

  margin-bottom: pxToRem(8);

  &:last-child {
    margin-bottom: 0;
  }

  & svg path {
    fill: #666666;
  }
}

.popupSideButtonText {
  color: rgba(0, 0, 0, 0.6);
  font-size: pxToRem(11);
}

.popupSideButtonIconDown {
  transform: rotate(0deg);
  animation-duration: 0.5s;
  transition: 0.2s ease;
}

.popupSideButtonIconUp {
  transform: rotate(-180deg);
  animation-duration: 0.5s;
  transition: 0.2s ease;
}

.popupSideIcon {
  width: pxToRem(94);
  height: pxToRem(128);
  box-sizing: border-box;
  border: pxToRem(1) solid #9c9c9c;
  border-radius: pxToRem(8);
  background: #ffffff;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  overflow: hidden;
  padding: pxToRem(8);
}

.popupSideIconImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.popupSideIconMargin {
  margin-bottom: pxToRem(8);
}

.popupContentRow {
  border-left: pxToRem(1) solid #4d4d4e;

  margin-bottom: pxToRem(5);
  padding-left: pxToRem(4);

  &:last-child {
    margin-bottom: 0;
  }

  p {
    margin: 0;
  }
}

.popupAdditional {
  font-size: pxToRem(14);
}

.popupAdditionalHide {
  display: none;
}

.popupAdditionalShow {
  display: block;
}

.popupAdditionalGroup {
  margin-bottom: pxToRem(8);

  &:last-child {
    margin-bottom: 0;
  }
}

.popupAdditionalLabel {
  color: #666666;
  font-size: pxToRem(10);

  margin-bottom: pxToRem(4);
}

.popupAdditionalValue {
  color: #4d4d4e;
  border-left: pxToRem(1) solid #4d4d4e;

  padding-left: pxToRem(5);
  margin-bottom: pxToRem(4);

  &:last-child {
    margin-bottom: 0;
  }
}

.popupAdditionalName {
  color: #0066ff;
}
