@import 'styles/function.scss';

.wrapper {
  display: flex;
}

.elementsColumn {
  width: pxToRem(560);
  min-width: pxToRem(450);
  flex-grow: 1;
}

.cardColumn {
  width: pxToRem(410);
  min-width: pxToRem(250);
}