@import 'styles/function.scss';

.layout {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: rgba(77, 77, 78, 0.6);
    z-index: 9999;
}

.default {
    border-radius: pxToRem(5);

    background-color: #f1f1f1;
}

.big {
    width: pxToRem(800);
    height: pxToRem(625);
}

.small {
    width: pxToRem(400);
    height: pxToRem(304);
}

.noFixedHeight {
    height: auto;
    max-height: pxToRem(768);
}
