@import 'styles/function.scss';

.dateFilter {
    display: flex;
    align-items: center;
    margin-right: pxToRem(4);
    margin-left: -(pxToRem(12));
    margin-bottom: pxToRem(24);
    margin-top: pxToRem(24);
}
.datePicker {
    margin-bottom: -(pxToRem(24));
    margin-top: -(pxToRem(24));
}
.table {
    width: pxToRem(790);
    margin: pxToRem(10);
    height: pxToRem(400);

    overflow-y: scroll;
}

.tableWrap {
    width: 100%;
}

.tableHead {
    background-color: #C4C4C4;
}

.tableHeadCell {
    font-weight: normal;
    font-size: pxToRem(18);
    font-weight: bold;
    color: #4D4D4E;
    padding: pxToRem(8);
}

.tableHeadCellFirst {
    width: pxToRem(82);
}

.tableHeadCellSecond {
    width: pxToRem(62);
}

.tableHeadCellThird {
    width: pxToRem(260);
}

.tableHeadCellFourth {
    width: pxToRem(377);
}

.tableRow {
    background-color: #E9E9E9;
}

.tableRowCell {
    font-weight: normal;
    font-size: pxToRem(14);
    color: #000000;
    padding: 0 pxToRem(5);
}

.tableRowCellDescription {
    word-break: break-word;
}

.tableRowCellAlignCenter {
    text-align: center;
}

.tableRowCellIconWrapper {
    display: inline-block;

    margin-right: pxToRem(8);

    &:last-child {
        margin-right: 0;
    }
}

.tableRowCellIcon {
    display: inline-block;

    width: pxToRem(128);
    height: pxToRem(128);

    border: 1px solid #4D4D4E;

    object-fit: contain;
}

