@import 'styles/function.scss';

.wrapperDropdown {
    position: relative;
    z-index: 1;
}

.wrapperDropdown .buttonWrapper {
    position: relative;
    z-index: 3;
}

.buttonWrapper {
    button {
        margin: 0;
    }
}

.dropdown {
    position: absolute;

    top: 100%;
    left: 0;

    background: #FFFFFF;

    font-weight: normal;
    list-style-type: none;
    overflow-y: auto;

    max-height: pxToRem(300);

    border-radius: pxToRem(4);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);

    padding: 0;
    z-index: 2;
}

.dropdownSmall {
    width: fit-content;
}

.dropdownLi {
    cursor: pointer;

    text-decoration: none;
    font-size: pxToRem(14);
    color: #4D4D4E;

    border-bottom: pxToRem(1) solid rgba(0, 0, 0, 0.05);

    padding: pxToRem(14) pxToRem(16);

    &:hover {
        background-color: #F7F7F7;
    }
}

.dropdownLiSmall {
    padding: pxToRem(5) pxToRem(16);
}

.wrapperDropdownDisabled {
    pointer-events: none;
}

