@import 'styles/function.scss';

.layout {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    overflow: hidden;
    background-color: #0066FF;
}

.content {
    width: pxToRem(400);
    height: pxToRem(300);
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.logo {
    width: pxToRem(300);
}

.block {
    height: 100%;
    color: #fff;
    position: relative;
}

.user {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: pxToRem(300);
}

.name {
    text-decoration: underline;
    margin: 0 pxToRem(24) 0 pxToRem(8);

    font-weight: 500;
    font-size: pxToRem(12);;
    line-height: pxToRem(14);;
}



.avatar {
    width: pxToRem(24);
    height: pxToRem(24);
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);

    margin: pxToRem(16) 0 pxToRem(16) pxToRem(20);
}

.menu {
    border: pxToRem(1) solid;
    width: 100%;
    min-width: pxToRem(230);;
    display: none;

    flex-direction: column;
    justify-content: center;

    position: absolute;
    top: pxToRem(56);

    background: #FFFFFF;
    /* input/videoPopup */

    box-shadow: 0 pxToRem(2) pxToRem(8) rgba(0, 0, 0, 0.2);
    border-radius: pxToRem(4);
    z-index: 9999999;
}

.menuItem {
    height: pxToRem(30);;
    padding: pxToRem(10) pxToRem(14);

    font-size: pxToRem(14);
    font-weight: bold;
    line-height: pxToRem(18);
    letter-spacing: 0.01em;
    cursor: pointer;

    display: flex;
    align-items: center;

    border-bottom: pxToRem(1) solid rgba(0, 0, 0, 0.05);

    color: #4D4D4E;

    &Title {
        margin-left: pxToRem(10);
    }
}

.menuItem:first-child {
    border-top: pxToRem(1) solid rgba(0, 0, 0, 0.05);
}

.menuItem:hover {
    color: #0066FF;
    font-weight: 500;
}
