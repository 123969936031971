@import 'styles/function.scss';

.container {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #0066ff;
  z-index: 2;
  padding: pxToRem(15) pxToRem(20);
}

.header {
  color: #fff;
  text-decoration: none;
}

.logoWrapper {
  display: flex;
  height: 100%;
  align-items: center;
}

.logo {
  width: pxToRem(195);
  min-width: pxToRem(80);
}

.logoLink {
  line-height: pxToRem(56);
}

.logoText {
  display: flex;
  flex-direction: column;
  margin-left: pxToRem(15);
}

.logoTextIsobr {
  font-weight: 900;
  font-size: pxToRem(16);
  line-height: pxToRem(19);
}

.logoTextGis {
  font-weight: 500;
  font-size: pxToRem(16);
  line-height: pxToRem(19);
}

.formLayout {
  position: relative;

  width: 100vw;
  height: calc(100vh - #{pxToRem(38)});

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:before {
    content: '';
    display: block;
    position: absolute;

    top: pxToRem(-10);
    right: pxToRem(-10);
    bottom: pxToRem(-10);
    left: pxToRem(-10);

    background-image: linear-gradient(rgba(0, 102, 255, 0.45), rgba(0, 102, 255, 0.45)),
    url('../../../assets//img//auth/auth-background.png');
    background-size: cover;
    background-repeat: no-repeat;

    filter: blur(5px);
    z-index: -1;
  }
}

.form {
  width: pxToRem(352);
  height: pxToRem(440);

  background: #FFFFFF;
  border-radius: pxToRem(8);
}

.formHeader {
  font-weight: bold;
  font-size: pxToRem(20);
  color: #4d4d4e;
  padding: pxToRem(14) pxToRem(24);
  border-bottom: pxToRem(1) solid #cccccc;
}


.formBody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: pxToRem(300);
  padding: pxToRem(14) pxToRem(24);
}

.formCheckboxWrappedStyle {
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
  height: 100%;
}

.formBodyCheckbox {
  font-weight: bold;
  font-size: pxToRem(14);
  line-height: 128%;
  letter-spacing: 0.01em;
  cursor: pointer;
}

.formBodyTextWrapper{
  display: inline-flex;
  height: 100%;
  vertical-align: middle;
}

.formBodyRef {
  font-weight: bold;
  font-size: pxToRem(14);
  line-height: 128%;
  letter-spacing: 0.01em;
  color: #0066ff;
  text-decoration-line: underline;
  cursor: pointer;
}

.formFooter {
  background: rgba(0, 191, 191, 0.2);
  display: flex;
  padding: pxToRem(10) pxToRem(24);
}

.formFooterIcon {
  width: pxToRem(40);
  height: pxToRem(40);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #37474F;

  margin-right: pxToRem(20);
}

.formFooterInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: bold;
  font-size: pxToRem(14);
  line-height: 128%;
  letter-spacing: 0.01em;
  color: #0066FF;
}

.footer {
  position: absolute;
  top: calc(100% - #{pxToRem(50)});
  left: pxToRem(10);
  right: calc(100% - #{pxToRem(10)});
  bottom: pxToRem(50);
  width: pxToRem(70);
  font-size: pxToRem(10);
  color: #fff;
}
