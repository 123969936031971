@import 'styles/function.scss';

.notification {
  &Wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-weight: 500;
    font-size: pxToRem(14);
    line-height: pxToRem(16);
    color: #4d4d4e;
    height: pxToRem(40);
    border-bottom: pxToRem(1) solid black;
  }
  &Info {
    padding-left: pxToRem(8);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  &Expand {
    margin-right: pxToRem(7);
  }
}

.nameHead {
  margin-left: pxToRem(11);
  color: black;
}

.headerButtons {
  display: flex;
}

.headerButtons svg,
.headerButtons img,
.notificationInfo {
  cursor: pointer;
}

.headerButtonsIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: pxToRem(28);
  height: pxToRem(20);
  margin: 0;

  &:not(:last-child) {
    margin-right: pxToRem(14);
  }
  &:last-child {
    margin-right: pxToRem(5);
  }

  & path {
    fill: black;
  }

  &Disabled {
    pointer-events: none;
    opacity: .5;
  }

  &Arrow {
    bottom: pxToRem(2);
    right: pxToRem(2);
  }
}

.headerButtonsItem svg,
.headerButtonsItem span {
  cursor: pointer;
}

.customTooltip {
  background: #ffffff !important;
  border: 0.5px solid #0066ff !important;
  border-radius: 5px !important;
  width: fit-content !important;
  height: fit-content !important;
  padding: pxToRem(5) pxToRem(5) !important;
  color: #000000 !important;
  font-size: pxToRem(11) !important;
  line-height: pxToRem(11) !important;
  opacity: 1 !important;
  margin-top: pxToRem(20) !important;
  margin-left: 0 !important;

  z-index: 9999999 !important;
}
