@import 'styles/function.scss';

.container {}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f5f5f5;
  zIndex: 999;
}

.createButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: pxToRem(16) 0;
}

.headBlock {
  display: flex;
  align-items: center;

  padding: pxToRem(10) pxToRem(5);

  border-bottom: pxToRem(1) solid #CCCCCC;
}

.headBlockMarginTop {
  margin-top: pxToRem(64);
}

.headBlockPlay {
  cursor: pointer;
  width: pxToRem(20);
}

.headBlockIcon {
  cursor: pointer;
  margin-left: pxToRem(10);
}

.headBlockTrack {
  cursor: pointer;
  margin-left: pxToRem(10);
  width: pxToRem(300);
}
.headBlockDelete {
  margin-left: auto;
  cursor: pointer;

  &Disabled {
    pointer-events: none;
    opacity: .5;
  }
}

.listBlock {}

.trackItem {
  display: flex;
  flex-direction: column;
  border-bottom: pxToRem(1) solid #CCCCCC;
  padding: pxToRem(5);
}

.trackItemMainRow {
  display: flex;
  align-items: center;
}

.trackItemMainRowPlay {
  margin-left: pxToRem(10);
  width: pxToRem(20);
}


.trackItemMainRowPlayDisabled {
  fill: #DADADA;

  & path {
    fill: #DADADA;
  }
}

.trackItemNameBlock {
  display: flex;
  flex-direction: column;
  color: #4D4D4E;
  font-size: pxToRem(14);
  width: pxToRem(275);
  margin-left: pxToRem(10);
}

.trackItemIconsGroup {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.trackItemColorRow {
  display: flex;
  align-items: center;
  font-size: pxToRem(12);
  color: #4D4D4E;
  margin: pxToRem(5) pxToRem(5) pxToRem(5) pxToRem(30);
}

.trackItemColorRowName {
  display: flex;
  flex-direction: column;
  margin-left: pxToRem(8);
}

.trackNameBlock {
  display: flex;
  flex-direction: column;
  color: #4D4D4E;
  font-size: pxToRem(12);
  width: pxToRem(300)
}

.headBlockIcon, .trackItemMainRowPlay, .trackEditIcon, .trackDeleteIcon {
  cursor: pointer;

  &Disabled {
    pointer-events: none;
    opacity: .5;
  }
}

.trackItemMainRowPlayDisabled {
  cursor: initial;
}

.modalContent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalTabIcon path {
  fill: #666666;
}

.modalTabIconActive path {
  fill: #2f80ed;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: pxToRem(63);

  padding: 0 pxToRem(24);
  font-weight: 500;
  font-size: pxToRem(20);
  line-height: pxToRem(23);
}

.modalControls {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  height: pxToRem(56);
  padding: 0 pxToRem(24);
}

.modalBody {
  height: pxToRem(500);
  border-bottom: pxToRem(1) solid rgba(0, 0, 0, 0.12);
  border-top: pxToRem(1) solid rgba(0, 0, 0, 0.12);
}

.modalBodyDelete {
  height: pxToRem(180);
  border-bottom: pxToRem(1) solid rgba(0, 0, 0, 0.12);
  border-top: pxToRem(1) solid rgba(0, 0, 0, 0.12);
}

.modalBodyDeleteText {
  font-size: pxToRem(14);
  padding: 0 pxToRem(24);
}

.modalBodyDeleteLabel {
  font-weight: 500;
  padding: pxToRem(15) pxToRem(30);
}

.closeModalIcon {
  cursor: pointer;
}
.closeModalIcon:hover {
  transform: scale(1.5);
}

.tabInfoDatePicker {
  width: pxToRem(400);
  // margin-bottom: -(pxToRem(24));
  margin-top: -(pxToRem(24));

  &::first-child {
    margin-bottom: -(pxToRem(24));
  }
}

.customTooltip {
  background: #ffffff !important;
  border: 0.5px solid #0066ff !important;
  border-radius: 5px !important;
  width: fit-content !important;
  height: fit-content !important;
  padding: pxToRem(5) pxToRem(5) !important;
  color: #000000 !important;
  font-size: pxToRem(11) !important;
  line-height: pxToRem(11) !important;
  opacity: 1 !important;
  margin-top: pxToRem(20) !important;
  margin-left: 0 !important;

  z-index: 9999999 !important;
}

.inputWrap {
  margin: pxToRem(24);
}
