@import 'styles/function.scss';

.title {
  user-select: none;
  font-size: pxToRem(16);
  font-weight: bold;
  line-height: pxToRem(20);
  padding-left: pxToRem(16);
  color: #4D4D4E;
  padding-bottom: pxToRem(8);
  border-bottom: 1px solid #ccc;
}

.children {
  position: relative;
  border-left: 1px solid #ccc;
  height: calc(100vh - 180px);
}

.withVerticalScroll {
  overflow-y: auto;
}

.resizabledItem {
  cursor: col-resize;
  position: absolute;
  width: 10px;

  top: 0;
  right: -5px;
  bottom: 0;
}

.isResize {
  user-select: none;
}