@import 'styles/function.scss';

.modalContent {
    display: flex;
    align-items: center;
}

.modalCol {
    width: pxToRem(400);
}

.inputWrap {
    margin: pxToRem(24);
}
