@import 'styles/function.scss';

.configItem {
  display: flex;
  align-items: center;
  min-height: pxToRem(32);

  color: #4d4d4e;

  padding: 0 pxToRem(10);
  border: pxToRem(1) solid #666666;
  border-radius: pxToRem(4);

  z-index: 5;

  &:not(:first-child) {
    margin-top: pxToRem(10);
  }
}

.dragIcon {
  flex: 0 0 auto;

  cursor: pointer;
}

.configItemLabel {
  margin-left: pxToRem(10);
}

.eyeIcon {
  flex: 0 0 auto;
  margin-left: auto;

  cursor: pointer;
}
