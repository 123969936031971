@import 'styles/function.scss';

.wrapper {
  width: 100%;
  height: calc(100vh - 86px);

  color: #4d4d4d;

  border-left: 1px solid black;
}

.header {
  user-select: none;
}

.headerTitle {
  font-size: pxToRem(18);
  padding: pxToRem(8) pxToRem(16);
  margin: 0;
}

.table {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: calc(100% - 100px);

  font-size: pxToRem(14);

  border-bottom: pxToRem(1) solid #dcdcdc;
}

.tableHeader {
  user-select: none;
}

.tableContent {
  flex-grow: 1;
  overflow-y: auto;
}

.tableRow {
  $col-width: 1fr;
  $table-paddings: pxToRem(18);

  display: grid;
  grid-template-columns: pxToRem(24) pxToRem(24) pxToRem(86) $col-width*12 $col-width*10 $col-width*12 pxToRem(130);
                      //  Checkbox       №          Дата        Название        Тип          Объект       Статус
  grid-template-rows: 1fr;
  align-items: center;
  grid-gap: pxToRem(8);

  width: calc(100% - pxToRem(36)); // 36 = $table-paddings * 2

  padding: pxToRem(8) $table-paddings;
  border-bottom: pxToRem(1) solid #dcdcdc;
}

.tableColumn {
  word-break: break-all;
}

.tableColumnBold {
  font-weight: bold;
}

.tableColumnName {
  cursor: pointer;
  text-decoration: underline;
  color: #0066FF;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;

  bottom: pxToRem(30);

  width: 100%;
  height: pxToRem(64);
}
