@import 'styles/function.scss';

.modalPaginationWrapper {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    margin: 0 pxToRem(24);
}

.modalPaginationSelectWrapper {
    display: flex;
    align-items: center;
    font-size: pxToRem(11);
    line-height: 128%;
    color: #666666;
    margin-right: auto;
}
.modalPaginationSelectLabel {
    margin-right: pxToRem(14);
}
.modalPaginationSelect {
    background-color: transparent;
    border: 0;
    border-bottom: pxToRem(1) solid #666666;

    color: #666666;
}

.modalPaginationPageWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
}

.modalPaginationPage {
    background-color: #0066FF;
    width: pxToRem(15);
    height: pxToRem(16);
    border-radius: 50%;
    color: #FFFFFF;
    margin: 0 pxToRem(8);

    font-style: normal;
    font-weight: bold;
    font-size: pxToRem(12);
    line-height: pxToRem(14);
    display: flex;
    align-items: center;
    justify-content: center;
}
.modalPaginationArrow {
    cursor: pointer;
}
.modalPaginationButtonWrapper {
    margin-left: auto;
}

.modalPaginationHideElement {
    visibility: hidden;
}