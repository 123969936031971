@import 'styles/function.scss';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    overflow-y: auto;

    padding-top: pxToRem(20);
}

.tabItem {
    display: flex;
    align-items: center;
    margin-bottom: pxToRem(10);

    padding: pxToRem(12) pxToRem(16);
    width: pxToRem(260);
    height: pxToRem(20);

    border: pxToRem(1) solid #666666;
    border-radius: pxToRem(4);

    z-index: 99999;

    &EyeIcon {
        cursor: pointer;
        margin-left: auto;
    }

    &Name {
        font-weight: bold;
        font-size: pxToRem(12);
        line-height: pxToRem(14);
        color: #4D4D4E;
        margin-left: pxToRem(10);
    }
}

.tabItemDragIcon {
    cursor: pointer;
}

.sortableListContainer {
    max-height: pxToRem(420);
}
