@import 'styles/function.scss';

.buttonBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: pxToRem(16) 0 pxToRem(8) 0;
  padding: 0 pxToRem(8);
}

.monitoringCreateButton {
  margin-left: pxToRem(8);

  button {
    width: pxToRem(120);
  }
}

.id {
  margin: 0 pxToRem(3);
  width: pxToRem(30);
  text-align: center;
  overflow: hidden;
}
.idHead {
  margin-left: pxToRem(12);
  width: pxToRem(40);
}
.itemImage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: pxToRem(17);
}
.nameWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: pxToRem(5);
  width: pxToRem(140);
}

.name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.unitListHeader {
  border-color: #000;
  color: #000;
}

.unitListHeader .name {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modalCol {
  width: pxToRem(290);
}

.modalColSlim {
  width: pxToRem(200);
}

.modalTabs {
  height: pxToRem(54);
  display: flex;
  align-items: center;
}

.modalTab {
  cursor: pointer;
  height: 100%;

  display: flex;
  flex: 1 100%;
  align-items: center;
  justify-content: center;

  font-weight: bold;
  font-size: pxToRem(16);
  line-height: pxToRem(20);

  color: #666;

  box-shadow: 0px pxToRem(4) pxToRem(4) rgba(0, 0, 0, 0.25);
}

.modalTabLabel {
  margin: pxToRem(10);
  padding-top: pxToRem(2);
}

.modalTabActive {
  color: #2f80ed;
  box-shadow: 0px pxToRem(4) pxToRem(4) rgba(0, 0, 0, 0.25), inset 0 -15px 0px -10px #2f80ed;
}

.closeModalIcon {
  cursor: pointer;
}
.closeModalIcon:hover {
  transform: scale(1.5);
}
.trackerListHeader {
  border-color: #000;
  color: #000;
}

.trackerListHeader .name {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modalContent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalTabIcon path {
  fill: #666666;
}

.modalTabIconActive path {
  fill: #2f80ed;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: pxToRem(63);

  padding: 0 pxToRem(24);
  font-weight: 500;
  font-size: pxToRem(20);
  line-height: pxToRem(23);
}

.multipleSelectWrapper {
  margin: 1.5rem;
}

.modalControls {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  height: pxToRem(56);
  padding: 0 pxToRem(24);
}

.modalBody {
  height: pxToRem(500);
  border-bottom: pxToRem(1) solid rgba(0, 0, 0, 0.12);
  border-top: pxToRem(1) solid rgba(0, 0, 0, 0.12);
}

.modalBodyDelete {
  height: pxToRem(180);
  border-bottom: pxToRem(1) solid rgba(0, 0, 0, 0.12);
  border-top: pxToRem(1) solid rgba(0, 0, 0, 0.12);
}

.modalBodyDeleteText {
  font-size: pxToRem(14);
  padding: 0 pxToRem(24);
}

.modalBodyDeleteLabel {
  font-weight: 500;
  padding: pxToRem(15) pxToRem(30);
}

.modalInfoLoading {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeModalIcon {
  cursor: pointer;
}
.closeModalIcon:hover {
  transform: scale(1.5);
}

.tabInfoDatePicker {
  width: pxToRem(293);
  // margin-bottom: -(pxToRem(24));
  margin-top: -(pxToRem(24));

  &:first-child {
    margin-bottom: -(pxToRem(24));
  }
}

.customTooltip {
  &#{&} {
    background: #ffffff;
    border: 0.5px solid #0066ff;
    border-radius: 5px;
    width: fit-content;
    height: fit-content;
    padding: pxToRem(5) pxToRem(5);
    color: #000000;
    font-size: pxToRem(11);
    line-height: pxToRem(11);
    opacity: 1;
    margin-top: pxToRem(20);
    margin-left: 0;

    z-index: 9999999;
  }
}

.photoWrap {
  margin: pxToRem(24);
}

.inputWrap {
  margin: pxToRem(22);
  width: 88%;
}

.dataPickerWrap {
  margin: pxToRem(24) 0 pxToRem(24);
}

.modalContentTransport {
  &#{&} {
    align-items: flex-start;
  }
  height: pxToRem(445);
  overflow-y: auto;
}

.modalContentEmployee, .modalContentTransport {
  &#{&} {
    align-items: flex-start;
  }
  height: pxToRem(445);
  overflow-y: auto;
}
