@import 'styles/function.scss';

.table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;

  thead {
    border-bottom: pxToRem(1) solid #000;

    th {
      padding: pxToRem(12);

      font-size: pxToRem(14);
      font-weight: 500;

      cursor: pointer;
      user-select: none;
    }
  }

  tbody {
    tr {
      border-bottom: pxToRem(1) solid #ccc;
      height: pxToRem(40);
    }

    td {
      padding: pxToRem(8) pxToRem(12);

      font-size: pxToRem(14);
      color: #4d4d4e;
    }
  }
}

.cellWithButton {
  text-align: right;
}

.cellButtonContainer {
  display: flex;
  justify-content: right;
  align-items: center;
}

.cellButton {
  cursor: pointer;
  user-select: none;
}
